import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function FirstModal({closeModal}) {
    const [isFaded, setIsFaded] = useState(false);

    const accessToken = localStorage.getItem('access_token');
    const baseUrl = process.env.REACT_APP_API_URL;

    const handleAccept = async () => {
        setIsFaded(true);

        try {
            const response = await fetch(`${baseUrl}/painel/usuario/primeiro-acesso/atualizar`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                closeModal();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center z-[99999]${isFaded ? ' transition-all opacity-0' : ''}`}>
            <div className="absolute inset-0 bg-black bg-opacity-50" onClick={handleAccept}>
            </div>
            <div className="animate-fade-in-down [animation-duration:_0.1s] rounded-sm border border-stroke bg-white w-full max-w-xl px-8 py-10 text-left shadow-md relative">
                <button type="button" className="absolute top-2 right-5 opacity-40 text-xl font-bold rotate-45" onClick={handleAccept}>+</button>

                <h1 className="text-secondary font-bold text-4xl mb-5">Bem vindo(a)!</h1>
                <p className="text-lg mb-10">Nessa etapa você preencherá todos os dados necessários para completar o seu cadastro.</p>
                <button type="button" className="block w-fit mx-auto bg-secondary text-white text-lg font-bold uppercase py-2 px-10 rounded-md hover:bg-opacity-80" onClick={handleAccept}>Entendi!</button>
            </div>
        </div>
    );
}

export default FirstModal;
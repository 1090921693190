import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

export function ReportGeneralItem({ member, isLast }) {
    return (
        <tr className={`${!isLast && 'border-b'}`}>
            <td className="p-2 2xl:p-4">
                <div className="flex items-center gap-2">
                    <span className="font-medium text-sm 2xl:text-base text-black leading-tight max-w-80">{member.nome_completo}</span>
                </div>
            </td>
            <td className="p-2 pr-0 text-left 2xl:p-4 xl:pr-0 text-xs 2xl:text-sm">{member.data_nascimento}</td>
            <td className="p-2 pr-0 text-left 2xl:p-4 xl:pr-0 text-xs 2xl:text-sm">{member.fone_celular}</td>
            <td className="p-2 text-left 2xl:p-4 text-xs 2xl:text-sm">{member.numero_passaporte}</td>
            <td className="p-2 text-left 2xl:p-4 text-xs 2xl:text-sm">{member.data_validade_passaporte}</td>
            <td className="p-2 text-left 2xl:p-4 text-xs 2xl:text-sm">{member.restricao_alimentar}</td>
            <td className="p-2 text-left 2xl:p-4 text-xs 2xl:text-sm">{member.limitacao}</td>
            <td className="p-2 text-left 2xl:p-4 text-xs 2xl:text-sm">{member.medicamento}</td>
            <td className="p-2 text-left 2xl:p-4 text-xs 2xl:text-sm">{member.problema_saude}</td>
            <td className="p-2 text-center min-w-max">

                {member.situacao ? (
                    <FontAwesomeIcon icon={faCheck} className="mr-2 text-2xl text-green-500" />
                ) : (
                    <FontAwesomeIcon icon={faXmark} className="mr-2 text-2xl text-red-500" />
                )}
            </td>
        </tr>
    );
}
import React, { useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

const ReportInsurancePrint = React.forwardRef(({ members, selectedDestiny }, ref) => {
    return (
        <div ref={ref} className="hidden print:block m-8">
            <h1 className="text-2xl font-bold mb-4">Relatório Geral - {selectedDestiny.label}</h1>
            <table className="w-full border-collapse">
                <thead>
                    <tr className="">
                        <th className="py-2 px-1 text-left text-xs font-normal uppercase">Nome</th>
                        <th className="py-2 px-1 text-left text-xs text-center font-normal uppercase">CPF</th>
                        <th className="py-2 px-1 text-left text-xs text-center font-normal uppercase">Nascimento</th>
                    </tr>
                </thead>
                <tbody>
                    {members.map((member, index) => (
                        <tr key={index} className="border-b border-gray-300">
                            <td className="py-2 px-1 text-[10px] uppercase whitespace-nowrap">{member.nome_completo}</td>
                            <td className="py-2 px-1 text-[10px] text-center">{member.cpf}</td>
                            <td className="py-2 px-1 text-[10px] text-center">{member.data_nascimento}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
});

export default ReportInsurancePrint;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { parse, format } from 'date-fns';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { ConfirmModal } from './ConfirmModal';

export function UserItem({ user, isSelected, isLast }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <tr className={`${!isLast && 'border-b'}`}>
            <td className="p-2 2xl:p-4">
                <div className="flex items-center gap-2">
                    <span className="font-medium text-sm 2xl:text-base text-black leading-tight max-w-80">{user.nome}</span>
                </div>
            </td>
            <td className="p-2 text-left 2xl:p-4 text-xs 2xl:text-sm">{user.email}</td>
            <td className="p-2 pr-0 text-left 2xl:p-4 xl:pr-0 text-xs 2xl:text-sm">{user.data}</td>
            <td className="p-2 2xl:p-4">
                <div className="flex items-center gap-2">
                    <Link to={`/manager/usuarios/editar/${user.id}`} className="bg-primary w-8 h-8 rounded-full flex items-center justify-center duration-200 ease-in-out hover:bg-opacity-80">
                        <FontAwesomeIcon icon={faEdit} className="text-white ml-[1px] mb-[1px]" />
                    </Link>

                    <button onClick={openModal} className="bg-red-700 w-8 h-8 pr-0.5 rounded-full flex items-center justify-center duration-200 ease-in-out hover:bg-opacity-80">
                        <FontAwesomeIcon icon={faTrash} className="text-white ml-[1px] mb-[1px]" />
                    </button>
                </div>

                {isModalOpen && <ConfirmModal icon={faTrash} closeModal={closeModal} type="deleteUser" confirm={`/manager/usuarios/excluir/${user.id}`} />}
            </td>
        </tr>
    );
}
import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useReactToPrint } from 'react-to-print';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import { NotificationMessage } from './NotificationMessage';
import { ReportGeneralItem } from './ReportGeneralItem';
import  ReportGeneralPrint from './ReportGeneralPrint';

export const ReportGeneralList = () => {
    const [loading, setLoading] = useState(true);
    const [destinies, setDestinies] = useState([]);
    const [selectedDestiny, setSelectedDestiny] = useState(null);
    const [members, setMembers] = useState([]);
    const [loadingMembers, setLoadingMembers] = useState(false);
    const [error, setError] = useState(null);
    const [notification, setNotification] = useState([]);

    const contentRef = useRef(null);
    const reactToPrintFn = useReactToPrint({ contentRef });

    const location = useLocation();
    const accessToken = localStorage.getItem('access_token');
    const baseUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (location.state?.message) {
            setNotification(location.state.message);

            window.history.replaceState({}, document.title);
        }
    }, [location.state]);

    useEffect(() => {
        const fetchDestinies = async () => {
            try {
                const response = await fetch(`${baseUrl}/manager/destinos`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Erro ao buscar os destinos.');
                }

                const data = await response.json();
                setDestinies(data.destinos.map(d => ({ value: d.id, label: d.destino })));
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDestinies();
    }, []);

    useEffect(() => {
        if (!selectedDestiny) return;

        setLoadingMembers(true);

        const fetchMembers = async () => {
            try {
                const response = await fetch(`${baseUrl}/manager/relatorios/participantes/geral/${selectedDestiny.value}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Erro ao buscar os participantes.');
                }

                const data = await response.json();
                setMembers(data.participantes);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoadingMembers(false);
            }
        };

        fetchMembers();
    }, [selectedDestiny]);

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            members.map((member) => ({
                Nome: member.nome_completo,
                Nascimento: member.data_nascimento,
                Fone: member.fone_celular,
                'Num. Passaporte': member.numero_passaporte,
                'Venc. Passaporte': member.data_validade_passaporte,
                'Rest. Alimentar': member.restricao_alimentar,
                Limitação: member.limitacao,
                Medicamento: member.medicamento,
                'Prob Saúde': member.problema_saude,
                Situação: member.situacao ? "Aprovado" : "Pendente",
            }))
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Participantes");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        saveAs(data, `Relatorio_Geral_${selectedDestiny?.label || "Geral"}.xlsx`);
    };

    if (loading) return (
        <div className="mx-auto w-fit py-10">
            <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
        </div>
    );

    if (error) {
        return <div className="text-2xl text-center text-red-600 font-bold my-10">{error}</div>;
    }

    return (
        <>
            <div className="mb-6 flex items-center justify-between">
                <h4 className="text-xl font-bold text-black">
                    Relatório geral
                </h4>

                <button className="px-4 py-1.5 rounded border border-green-600 transition-all not-disabled:hover:bg-green-800 not-disabled:hover:border-green-800 bg-green-600 text-white ml-auto disabled:opacity-40" disabled={!selectedDestiny} onClick={exportToExcel}>
                    <FontAwesomeIcon icon={faDownload} className="mr-2" />
                    Exportar Excel
                </button>

                <button className="px-4 py-1.5 rounded border border-primary transition-all not-disabled:hover:bg-slate-600 not-disabled:hover:border-slate-600 bg-primary text-white ml-3 disabled:opacity-40" disabled={!selectedDestiny} onClick={() => reactToPrintFn()}>
                    <FontAwesomeIcon icon={faPrint} className="mr-2" />
                    Imprimir
                </button>
            </div>

            <div className="mb-6 w-1/2 flex gap-4 items-center">
                <label className="block text-sm font-medium">Destino:</label>
                <Select
                    name="destinos"
                    options={destinies}
                    onChange={setSelectedDestiny}
                    placeholder="Selecione um destino..."
                    className="min-w-40"
                />
            </div>

            {selectedDestiny && (
                loadingMembers ? (
                    <div className="mx-auto w-fit py-10">
                        <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
                    </div>
                ) : (
                    <div className="flex flex-col mb-2">
                        <table className="table-auto border-collapse">
                            <thead className="bg-slate-100">
                                <tr>
                                    <th className="p-2 pl-0 2xl:pl-4 text-left text-sm font-medium uppercase">Nome</th>
                                    <th className="p-2 text-left 2xl:p-4 text-sm font-medium uppercase">Nascimento</th>
                                    <th className="p-2 text-left 2xl:p-4 text-sm font-medium uppercase">Fone</th>
                                    <th className="p-2 text-left 2xl:p-4 text-sm font-medium uppercase">Num. Pass.</th>
                                    <th className="p-2 text-left 2xl:p-4 text-sm font-medium uppercase">Venc. Pass.</th>
                                    <th className="p-2 text-left 2xl:p-4 text-sm font-medium uppercase">Rest. Alimentar</th>
                                    <th className="p-2 text-left 2xl:p-4 text-sm font-medium uppercase">Limitação</th>
                                    <th className="p-2 text-left 2xl:p-4 text-sm font-medium uppercase">Medicação</th>
                                    <th className="p-2 text-left 2xl:p-4 text-sm font-medium uppercase">Prob Saúde</th>
                                    <th className="w-24 p-2 text-left 2xl:p-4 text-sm font-medium uppercase">Situação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {members.map((member, index) => (
                                    <ReportGeneralItem
                                        key={index}
                                        member={member}
                                        isSelected={false}
                                        isLast={index === members.length - 1}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                )
            )}

            {selectedDestiny && members.length > 0 && (
                    <ReportGeneralPrint
                        ref={contentRef}
                        members={members}
                        selectedDestiny={selectedDestiny}
                    />
            )}

            {notification && (
                <NotificationMessage
                    type={notification.type}
                    message={notification.text}
                    show={location.state}
                    onClose={() => setNotification(null)}
                />
            )}
        </>
    );
};

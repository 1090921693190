import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InputMask } from '@react-input/mask';
import Select from 'react-select';

import { useToken } from './TokenContext';
import FirstModal from './FirstModal';

function FormData() {
    const [loading, setLoading] = useState(true);
    const [destinations, setDestinations] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userData, setUserData] = useState({
        destinos: [],
        nome_completo: '',
        cpf: '',
        data_nascimento: '',
        email: '',
        rg: '',
        data_expedicao_rg: '',
        numero: '',
        paginas_em_branco: '',
        data_emissao: '',
        data_validade: '',
        fone_celular: '',
        fone_emergencia: '',
        restricao_alimentar: false,
        restricao_alimentar_qual: '',
        limitacao_qual: false,
        limitacao_qual_qual: '',
        medicamento: false,
        medicamento_qual: '',
        medicamento_dosagem: '',
        problema_saude: false,
        problema_saude_qual: ''
    });

    const [userErrorData, setUserErrorData] = useState({
        destinos: [],
        nome_completo: [],
        cpf: [],
        data_nascimento: [],
        email: [],
        rg: [],
        data_expedicao_rg: [],
        numero: [],
        paginas_em_branco: [],
        data_emissao: [],
        data_validade: [],
        fone_celular: [],
        fone_emergencia: [],
        restricao_alimentar: [],
        restricao_alimentar_qual: [],
        limitacao_qual: [],
        limitacao_qual_qual: [],
        medicamento: [],
        medicamento_qual: [],
        medicamento_dosagem: [],
        problema_saude: [],
        problema_saude_qual: [],
    });

    const [formLoading, setFormLoading] = useState(false);
    const [error, setError] = useState('');

    const executedRef = useRef(false);

    const { tokenData } = useToken();
    const navigate = useNavigate();
    const { id } = useParams();
    const accessToken = localStorage.getItem('access_token');
    const baseUrl = process.env.REACT_APP_API_URL;

    const fetchDestinations = async () => {
        try {
            const response = await fetch(`${baseUrl}/destinos`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error('Erro ao buscar os destinos.');
            }

            const data = await response.json();
            setDestinations(data.destinos);

        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchUser = async () => {
        try {
            const response = await fetch(`${baseUrl}/painel/usuario`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error('Erro ao buscar o usuário.');
            }

            const data = await response.json();
            setUserData(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        fetchDestinations();
        fetchUser();
    }, []);

    useEffect(() => {
    if (!executedRef.current && userData?.primeiro_acesso === false) {
            setIsModalOpen(true);
            executedRef.current = true;
        }
    }, [userData]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSelectChange = (name, selectedOption) => {
        setUserData(prevData => ({
            ...prevData,
            [name]: selectedOption.value
        }));
    };

    const selectOptions = [
        { value: false, label: 'Não' },
        { value: true, label: 'Sim' },
    ];

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (userData.aprovado_bloqueado) {
            return;
        }

        setFormLoading(true);
        try {
            const response = await fetch(`${baseUrl}/painel/usuario/atualizar`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(userData)
            });
            if (response.ok) {
                setFormLoading(false);
                
                navigate('/painel/upload-documentos', { state: { message: { type: 'success', text: 'Cadastro atualizado com sucesso!' } } });

            } else {
                if (response.status === 422) {
                    const errors = await response.json();

                    setFormLoading(false);
                    setUserErrorData(errors);
                    setError('Erro ao atualizar o usuário');
                }
            }
        } catch (error) {
            setFormLoading(false);
            setError(error.message);
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setUserData({
            ...userData,
            primeiro_acesso: false,
        })
    };

    if (loading) return (
        <div className="mx-auto py-10">
            <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
        </div>
    );

    return (
        <>
            <form onSubmit={handleSubmit} className="max-w-5xl pb-10">
                <div className="mb-6 2xl:mb-10">
                    <h4 className="flex gap-6 items-center font-secondary text-md font-bold whitespace-nowrap mb-3 2xl:mb-5">Selecione seu destino: <span className="w-full border-t" /></h4>
                    <div className="mb-3 flex gap-5 lg:gap-5 flex-row">
                        {Array.isArray(destinations) && destinations.length > 0 && (
                            destinations.map((destination, index) => (
                                <div className="lg:w-1/5" key={index}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="destinos[]"
                                            checked={userData.destinos.includes(destination.id)}
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                setUserData((prevData) => {
                                                    const newDestinos = checked
                                                        ? [...prevData.destinos, destination.id]
                                                        : prevData.destinos.filter((id) => id !== destination.id);
                                                    return { ...prevData, destinos: newDestinos };
                                                });

                                                setUserErrorData(prevData => ({...prevData, destinos: []}))
                                            }}
                                            value={destination.id}
                                            className="peer sr-only"
                                            disabled={userData.aprovado_bloqueado}
                                        />
                                        <span className="block py-3 px-6 bg-slate-50 peer-checked:bg-secondary peer-checked:text-white text-center font-bold rounded-md border border-gray-300 peer-checked:border-secondary uppercase transition-all duration-200 cursor-pointer hover:bg-slate-100">{destination.destino}</span>
                                    </label>
                                </div>
                            ))
                        )}
                    </div>
                    {userErrorData.destinos && userErrorData.destinos[0] && (<span className="max-w-xl flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.destinos[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, destinos: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                </div>

                <div className="mb-6 2xl:mb-10">
                    <h4 className="flex gap-6 items-center font-secondary text-md font-bold whitespace-nowrap mb-3 2xl:mb-5">Dados pessoais <span className="w-full border-t" /></h4>
                    <div className="mb-3 flex gap-3 lg:gap-5 flex-col lg:flex-row">
                        <div className="lg:w-1/2">
                            <label className="block text-sm mb-1">Nome completo: <span className="text-slate-500 font-light">(Igual ao passaporte)</span></label>
                            <input
                                type="text"
                                name="nome_completo"
                                value={userData.nome_completo}
                                onClick={() => setUserErrorData(prevData => ({...prevData, nome_completo: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.nome_completo && userErrorData.nome_completo[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.nome_completo[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, nome_completo: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>

                        <div className="w-full lg:w-1/4">
                            <label className="block text-sm mb-1">CPF:</label>
                            <InputMask
                                type="text"
                                name="cpf"
                                mask="___.___.___-__"
                                replacement={{ _: /\d/ }}
                                value={userData.cpf}
                                onClick={() => setUserErrorData(prevData => ({...prevData, cpf: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.cpf && userErrorData.cpf[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.cpf[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, cpf: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>

                        <div className="w-full lg:w-1/5">
                            <label className="block text-sm mb-1">Data de Nascimento:</label>
                            <input
                                type="date"
                                name="data_nascimento"
                                value={userData.data_nascimento}
                                onClick={() => setUserErrorData(prevData => ({...prevData, data_nascimento: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                max={new Date().toJSON().slice(0, 10)}
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.data_nascimento && userErrorData.data_nascimento[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.data_nascimento[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, data_nascimento: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>
                    </div>

                    <div className="mb-3 flex gap-3 lg:gap-5 flex-col lg:flex-row">
                        <div className="w-full lg:w-1/2">
                            <label className="block text-sm mb-1">E-mail:</label>
                            <input
                                type="email"
                                name="email"
                                value={userData.email}
                                onClick={() => setUserErrorData(prevData => ({...prevData, email: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.email && userErrorData.email[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.email[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, email: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>

                        <div className="w-full lg:w-1/4">
                            <label className="block text-sm mb-1">RG:</label>
                            <input
                                type="text"
                                name="rg"
                                value={userData.rg}
                                onClick={() => setUserErrorData(prevData => ({...prevData, rg: []}))}
                                onChange={handleChange}
                                maxLength="20"
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                max={new Date().toJSON().slice(0, 10)}
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.rg && userErrorData.rg[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.rg[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, rg: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>

                        <div className="w-full lg:w-1/5">
                            <label className="block text-sm mb-1">Data de expedição RG:</label>
                            <input
                                type="date"
                                name="data_expedicao_rg"
                                value={userData.data_expedicao_rg}
                                onClick={() => setUserErrorData(prevData => ({...prevData, data_expedicao_rg: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                max={new Date().toJSON().slice(0, 10)}
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.data_expedicao_rg && userErrorData.data_expedicao_rg[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.data_expedicao_rg[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, data_expedicao_rg: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>
                    </div>
                </div>

                <div className="mb-6 2xl:mb-10">
                    <h4 className="flex gap-6 items-center font-secondary text-md font-bold whitespace-nowrap mb-3 2xl:mb-5">Passaporte <span className="w-full border-t" /></h4>
                    <div className="mb-3 flex gap-3 lg:gap-5 flex-col lg:flex-row">
                        <div className="w-full lg:w-1/5">
                            <label className="block text-sm mb-1">Número do passaporte:</label>
                            <input
                                type="text"
                                name="numero"
                                value={userData.numero}
                                onClick={() => setUserErrorData(prevData => ({...prevData, numero: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.numero && userErrorData.numero[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.numero[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, numero: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>

                        <div className="w-full lg:w-1/5">
                            <label className="block text-sm mb-1">Páginas em branco:</label>
                            <input
                                type="number"
                                name="paginas_em_branco"
                                min="0"
                                max="100"
                                value={userData.paginas_em_branco}
                                onClick={() => setUserErrorData(prevData => ({...prevData, paginas_em_branco: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.paginas_em_branco && userErrorData.paginas_em_branco[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.paginas_em_branco[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, paginas_em_branco: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>

                        <div className="w-full lg:w-1/5">
                            <label className="block text-sm mb-1">Data de Emissão:</label>
                            <input
                                type="date"
                                name="data_emissao"
                                value={userData.data_emissao}
                                onClick={() => setUserErrorData(prevData => ({...prevData, data_emissao: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                max={new Date().toJSON().slice(0, 10)}
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.data_emissao && userErrorData.data_emissao[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.data_emissao[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, data_emissao: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>

                        <div className="w-full lg:w-1/5">
                            <label className="block text-sm mb-1">Data de Validade:</label>
                            <input
                                type="date"
                                name="data_validade"
                                value={userData.data_validade}
                                onClick={() => setUserErrorData(prevData => ({...prevData, data_validade: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.data_validade && userErrorData.data_validade[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.data_validade[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, data_validade: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>
                    </div>
                </div>

                <div className="mb-6 2xl:mb-10">
                    <h4 className="flex gap-6 items-center font-secondary text-md font-bold whitespace-nowrap mb-3 2xl:mb-5">Telefone <span className="w-full border-t" /></h4>
                    <div className="mb-3 flex gap-3 lg:gap-5 flex-col lg:flex-row">
                        <div className="w-full lg:w-1/4">
                            <label className="block text-sm mb-1">Whatsapp:</label>
                            <InputMask
                                type="text"
                                name="fone_celular"
                                mask="(__) _____-____"
                                replacement={{ _: /\d/ }}
                                value={userData.fone_celular}
                                onClick={() => setUserErrorData(prevData => ({...prevData, fone_celular: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.fone_celular && userErrorData.fone_celular[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.fone_celular[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, fone_celular: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>

                        <div className="w-full lg:w-1/4">
                            <label className="block text-sm mb-1">Telefone de emergência:</label>
                            <InputMask
                                type="text"
                                name="fone_emergencia"
                                mask="(__) _____-____"
                                replacement={{ _: /\d/ }}
                                value={userData.fone_emergencia}
                                onClick={() => setUserErrorData(prevData => ({...prevData, fone_emergencia: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.fone_emergencia && userErrorData.fone_emergencia[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.fone_emergencia[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, fone_emergencia: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>
                    </div>
                </div>

                <div className="mb-6 2xl:mb-10">
                    <h4 className="flex gap-6 items-center font-secondary text-md font-bold whitespace-nowrap mb-3 2xl:mb-5">Dados adicionais <span className="w-full border-t" /></h4>
                    <div className="mb-3 flex gap-3 lg:gap-5 flex-col lg:flex-row">
                        <div className="w-full lg:w-2/5">
                            <label className="block text-sm mb-1">O Sr.(a) possui alguma restrição alimentar?</label>
                            <Select
                                name="restricao_alimentar"
                                options={selectOptions}
                                value={selectOptions.find(option => option.value === userData.restricao_alimentar) || null}
                                onClick={() => setUserErrorData(prevData => ({...prevData, restricao_alimentar: []}))}
                                onChange={selectedOption => handleSelectChange('restricao_alimentar', selectedOption)}
                                placeholder="Selecione uma opção..."
                                className="user-select"
                            />
                            {userErrorData.restricao_alimentar && userErrorData.restricao_alimentar[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.restricao_alimentar[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, restricao_alimentar: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>

                        <div className={`w-full lg:w-3/5 origin-top lg:origin-left transition-all ${userData.restricao_alimentar ? 'scale-y-100 lg:scale-y-100 lg:scale-x-100' : 'scale-y-0 lg:scale-y-100 lg:scale-x-0'}`}>
                            <label className="block text-sm mb-1">Qual?</label>
                            <input
                                type="text"
                                name="restricao_alimentar_qual"
                                value={userData.restricao_alimentar_qual ?? ''}
                                onClick={() => setUserErrorData(prevData => ({...prevData, restricao_alimentar_qual: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.restricao_alimentar_qual && userErrorData.restricao_alimentar_qual[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.restricao_alimentar_qual[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, restricao_alimentar_qual: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>
                    </div>

                    <div className={`mb-3 flex gap-3 lg:gap-5 flex-col lg:flex-row transition-all duration-300${!userData.restricao_alimentar ? ' max-lg:-mt-16' : ''}`}>
                        <div className="w-full lg:w-2/5">
                            <label className="block text-sm mb-1">O Sr.(a) possui alguma limitação física?</label>
                            <Select
                                name="limitacao"
                                options={selectOptions}
                                value={selectOptions.find(option => option.value === userData.limitacao) || null}
                                onClick={() => setUserErrorData(prevData => ({...prevData, limitacao: []}))}
                                onChange={selectedOption => handleSelectChange('limitacao', selectedOption)}
                                placeholder="Selecione uma opção..."
                                className="user-select"
                            />
                            {userErrorData.limitacao && userErrorData.limitacao[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.limitacao[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, limitacao: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>

                        <div className={`w-full lg:w-3/5 origin-top lg:origin-left transition-all ${userData.limitacao ? 'scale-y-100 lg:scale-y-100 lg:scale-x-100' : 'scale-y-0 lg:scale-y-100 lg:scale-x-0'}`}>
                            <label className="block text-sm mb-1">Qual?</label>
                            <input
                                type="text"
                                name="limitacao_qual"
                                value={userData.limitacao_qual ?? ''}
                                onClick={() => setUserErrorData(prevData => ({...prevData, limitacao_qual: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.limitacao_qual && userErrorData.limitacao_qual[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.limitacao_qual[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, limitacao_qual: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>
                    </div>

                    <div className={`mb-3 flex gap-3 lg:gap-5 flex-col lg:flex-row transition-all duration-300${!userData.limitacao ? ' max-lg:-mt-16' : ''}`}>
                        <div className="w-full lg:w-2/5">
                            <label className="block text-sm mb-1">O Sr.(a) faz uso regular de algum medicamento?</label>
                            <Select
                                name="medicamento"
                                options={selectOptions}
                                value={selectOptions.find(option => option.value === userData.medicamento) || null}
                                onChange={selectedOption => handleSelectChange('medicamento', selectedOption)}
                                placeholder="Selecione uma opção..."
                                className="user-select"
                            />
                            {userErrorData.medicamento && userErrorData.medicamento[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.medicamento[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, medicamento: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>

                        <div className={`w-full lg:w-3/5 flex gap-3 lg:gap-5 flex-row origin-top lg:origin-left transition-all ${userData.medicamento ? 'scale-y-100 lg:scale-y-100 lg:scale-x-100' : 'scale-y-0 lg:scale-y-100 lg:scale-x-0'}`}>
                            <div className="w-3/5">
                                <label className="block text-sm mb-1">Qual?</label>
                                <input
                                    type="text"
                                    name="medicamento_qual"
                                    value={userData.medicamento_qual ?? ''}
                                    onClick={() => setUserErrorData(prevData => ({...prevData, medicamento_qual: []}))}
                                    onChange={handleChange}
                                    className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                    disabled={userData.aprovado_bloqueado}
                                />
                                {userErrorData.medicamento_qual && userErrorData.medicamento_qual[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.medicamento_qual[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, medicamento_qual: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                            </div>

                            <div className="w-2/5">
                                <label className="block text-sm mb-1">Dosagem:</label>
                                <input
                                    type="text"
                                    name="medicamento_dosagem"
                                    value={userData.medicamento_dosagem ?? ''}
                                    onClick={() => setUserErrorData(prevData => ({...prevData, medicamento_dosagem: []}))}
                                    onChange={handleChange}
                                    className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                    disabled={userData.aprovado_bloqueado}
                                />
                                {userErrorData.medicamento_dosagem && userErrorData.medicamento_dosagem[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.medicamento_dosagem[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, medicamento_dosagem: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                            </div>
                        </div>
                    </div>

                    <div className={`mb-3 flex gap-3 lg:gap-5 flex-col lg:flex-row transition-all duration-300${!userData.medicamento ? ' max-lg:-mt-16' : ''}`}>
                        <div className="w-full lg:w-2/5">
                            <label className="block text-sm mb-1">O Sr.(a) possui algum problema de saúde?</label>
                            <Select
                                name="problema_saude"
                                options={selectOptions}
                                value={selectOptions.find(option => option.value === userData.problema_saude) || null}
                                onClick={() => setUserErrorData(prevData => ({...prevData, problema_saude: []}))}
                                onChange={selectedOption => handleSelectChange('problema_saude', selectedOption)}
                                placeholder="Selecione uma opção..."
                                className="user-select"
                            />
                            {userErrorData.problema_saude && userErrorData.problema_saude[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.problema_saude[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, problema_saude: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>

                        <div className={`w-full lg:w-3/5 origin-left transition-all ${userData.problema_saude ? 'scale-x-100' : 'scale-x-0'}`}>
                            <label className="block text-sm mb-1">Qual?</label>
                            <input
                                type="text"
                                name="problema_saude_qual"
                                value={userData.problema_saude_qual ?? ''}
                                onClick={() => setUserErrorData(prevData => ({...prevData, problema_saude_qual: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.problema_saude_qual && userErrorData.limitacao_qual[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.problema_saude_qual[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, problema_saude_qual: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>
                    </div>
                </div>

                <button
                    type="submit"
                    className={`w-full bg-secondary text-white py-2 px-4 rounded-md hover:bg-opacity-80 disabled:bg-opacity-80 disabled:cursor-not-allowed transition-[margin-top] duration-300${!userData.problema_saude ? ' max-lg:-mt-24' : ''}`}
                    disabled={userData.aprovado_bloqueado}
                >
                    {!formLoading ? (
                        'Salvar'
                    ) : (
                        <div role="status" className="flex justify-center items-center">
                            <svg aria-hidden="true" className="w-6 h-6 text-white text-opacity-60 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    )}
                </button>
            </form>

            {isModalOpen && (
                <FirstModal closeModal={closeModal} />
            )}
        </>
    );
}

export default FormData;
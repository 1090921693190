import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

export function ReportInsuranceItem({ member, isLast }) {
    return (
        <tr className={`${!isLast && 'border-b'}`}>
            <td className="p-2 2xl:p-4">
                <div className="flex items-center gap-2">
                    <span className="font-medium text-sm 2xl:text-base text-black leading-tight max-w-80">{member.nome_completo}</span>
                </div>
            </td>
            <td className="p-2 pr-0 text-left 2xl:p-4 xl:pr-0 text-xs 2xl:text-sm">{member.cpf}</td>
            <td className="p-2 pr-0 text-left 2xl:p-4 xl:pr-0 text-xs 2xl:text-sm">{member.data_nascimento}</td>
        </tr>
    );
}
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InputMask } from '@react-input/mask';

import { NotificationMessage } from './NotificationMessage';

function FormData() {
    const [userData, setUserData] = useState({
        nome: '',
        email: '',
        cidade: '',
        telefone: '',
        mensagem: '',
    });

    const [userErrorData, setUserErrorData] = useState({
        nome: [],
        email: [],
        cidade: [],
        telefone: [],
        mensagem: [],
    });

    const [formLoading, setFormLoading] = useState(false);
    const [error, setError] = useState('');

    const [notification, setNotification] = useState({
        type: 'info',
        message: '',
        show: false,
    });

    const navigate = useNavigate();
    const accessToken = localStorage.getItem('access_token');
    const baseUrl = process.env.REACT_APP_API_URL;
    
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (userData.aprovado_bloqueado) {
            return;
        }

        setFormLoading(true);
        try {
            const response = await fetch(`${baseUrl}/painel/usuario/contato/enviar`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(userData)
            });
            if (response.ok) {
                setFormLoading(false);

                setUserData({
                    nome: '',
                    email: '',
                    cidade: '',
                    telefone: '',
                    mensagem: '',
                });
                
                setNotification({
                    type: 'success',
                    message: 'Contato enviado com sucesso!',
                    show: true,
                });

            } else {
                if (response.status === 422) {
                    const errors = await response.json();

                    setFormLoading(false);
                    setUserErrorData(errors);
                    setError('Erro ao atualizar o usuário');
                }
            }
        } catch (error) {
            setFormLoading(false);
            setError(error.message);
        }
    };
    
    const handleCloseNotification = () => {
        setNotification((prev) => ({ ...prev, show: false }));
    };

    return (
        <>
            <form onSubmit={handleSubmit} className="relative lg:w-1/2 pb-10 pr-4 md:pr-8 2xl:pr-20 before:content-none lg:before:content-[''] before:absolute before:bottom-0 before:-top-3 before:right-0 before:border-r">
                <h5 className="mb-6 2xl:mb-10 2xl:mb-1 pt-3 2xl:pt-5">Utilize o formulário abaixo para enviar suas dúvidas para agência de turismo<br /> ou para contratar serviços adicionais.</h5>

                <div className="mb-6 2xl:mb-10">
                    <div className="mb-3 flex gap-5 lg:gap-15 flex-col lg:flex-row">
                        <div className="w-full">
                            <label className="block text-sm mb-1">Nome completo:</label>
                            <input
                                type="text"
                                name="nome"
                                value={userData.nome}
                                onClick={() => setUserErrorData(prevData => ({...prevData, nome: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                            />
                            {userErrorData.nome && userErrorData.nome[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.nome[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, nome: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>
                    </div>

                    <div className="mb-3 flex gap-5 lg:gap-15 flex-col lg:flex-row">
                        <div className="w-full">
                            <label className="block text-sm mb-1">E-mail:</label>
                            <input
                                type="email"
                                name="email"
                                value={userData.email}
                                onClick={() => setUserErrorData(prevData => ({...prevData, email: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                            />
                            {userErrorData.email && userErrorData.email[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.email[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, email: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>
                    </div>

                    <div className="mb-3 flex gap-5 lg:gap-15 flex-col lg:flex-row">
                        <div className="w-full">
                            <label className="block text-sm mb-1">Cidade/Estado:</label>
                            <input
                                type="text"
                                name="cidade"
                                value={userData.cidade}
                                onClick={() => setUserErrorData(prevData => ({...prevData, cidade: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                            />
                            {userErrorData.cidade && userErrorData.cidade[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.cidade[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, cidade: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>
                    </div>

                    <div className="mb-3 flex gap-5 lg:gap-15 flex-col lg:flex-row">
                        <div className="w-full">
                            <label className="block text-sm mb-1">Telefone de contato:</label>
                            <InputMask
                                type="text"
                                name="telefone"
                                mask="(__) _____-____"
                                replacement={{ _: /\d/ }}
                                value={userData.telefone}
                                onClick={() => setUserErrorData(prevData => ({...prevData, telefone: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                            />
                            {userErrorData.telefone && userErrorData.telefone[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.telefone[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, telefone: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>
                    </div>

                    <div className="mb-3 flex gap-5 lg:gap-15 flex-col lg:flex-row">
                        <div className="w-full">
                            <label className="block text-sm mb-1">Mensagem:</label>
                            <textarea
                                name="mensagem"
                                value={userData.mensagem}
                                onClick={() => setUserErrorData(prevData => ({...prevData, mensagem: []}))}
                                onChange={handleChange}
                                className="w-full h-40 resize-none px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                            />
                            {userErrorData.mensagem && userErrorData.mensagem[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.mensagem[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, mensagem: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>
                    </div>
                </div>

                <button
                    type="submit"
                    className="relative bg-white text-secondary font-bold uppercase py-2 px-6 border-2 border-secondary rounded-md"
                >
                    {!formLoading ? (
                        'Enviar Mensagem'
                    ) : (
                        <>
                            <div role="status" className="absolute inset-0 flex justify-center items-center">
                                <svg aria-hidden="true" className="w-6 h-6 text-secondary text-opacity-60 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
                            <span className="opacity-0">Enviar Mensagem</span>
                        </>
                    )}
                </button>
            </form>

            {notification && notification.show && (
                <NotificationMessage
                    type={notification.type}
                    message={notification.message}
                    show={notification.show}
                    onClose={handleCloseNotification}
                />
            )}
        </>
    );
}

export default FormData;
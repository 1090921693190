import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useToken } from './TokenContext';

const ProtectedRoute = ({ element, admin=false }) => {
    const { isAuthenticated, checkToken } = useToken();
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        if (isAuthenticated === null) {
            checkToken();
        }
    }, [isAuthenticated, checkToken]);

    useEffect(() => {
        const timer = setTimeout(() => setFadeOut(true), 600);
        return () => clearTimeout(timer);
    }, []);

    if (isAuthenticated === null) {
        return (
            <div className={`fixed left-0 top-0 z-[999999] flex h-screen w-screen items-center justify-center bg-white transition-opacity duration-300 ${fadeOut ? 'opacity-0' : 'opacity-100'}`}>
                <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-secondary border-t-transparent"></div>
            </div>
        );
    }

    return isAuthenticated ? element : <Navigate to={`${admin ? '/manager/login' : '/login/'}`} replace />;
};

export default ProtectedRoute;
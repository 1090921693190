import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import AuthLayout from '../Layouts/AuthLayout';

import FormNewPassword from '../Components/FormNewPassword';

function App() {
    const navigate = useNavigate();
    const { token } = useParams();
    const [error, setError] = useState(null);

    const baseUrl = process.env.REACT_APP_API_URL;

    const fetchToken = async () => {
        try {
            const response = await fetch(`${baseUrl}/senha/verificar-token/${token}`, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Erro ao buscar o token.');
            }

            const data = await response.json();
            console.log(data)
            if (!data.token) {
                navigate('/login', {
                    state: { message: { type: 'error', text: 'Este link expirou ou já foi utilizado. Solicite a alteração de senha novamente.' } },
                });
            }

        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchToken();
    }, []);

    return (
        <AuthLayout>
            <div className="mx-auto w-full max-w-[480px]">
                <div className="text-center">
                    <div
                        className="mx-auto mb-12 flex flex-col"
                    >
                        <img
                            src={`/assets/img/todeschini-logo.png`}
                            alt="logo"
                            className="block mb-5 mx-auto pl-[9%]"
                        />

                        <img
                            src={`/assets/img/experience-logo.png`}
                            alt="logo"
                            className="block w-1/2 max-w-[206px] mx-auto"
                        />
                    </div>

                    <FormNewPassword />
                </div>
            </div>
        </AuthLayout>
    );
}

export default App;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { parse, format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

function MemberItem({ row, onSelect, isSelected }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const member = row.original;

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleCheckboxChange = () => onSelect(member.id);

    const { key, ...rowProps } = row.getRowProps();

    const step = {
        etapa2: { label: 'Etapa Inicial', color: 'bg-yellow-500' },
        dados_adicionais: { label: 'Dados Adicionais', color: 'bg-blue-500' },
        concluido: { label: 'Documentos enviados', color: 'bg-green-500' },
    };

    return (
        <tr {...rowProps} className="bmember-b bmember-stroke">
            <td className="text-left xl:p-4 text-sm font-medium">
                <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={handleCheckboxChange}
                />
            </td>
            <td className="p-2 2xl:p-4 xl:pl-0 text-left font-medium uppercase">{member.nome}</td>
            <td className="p-2 2xl:p-4 text-sm text-black font-medium leading-tight max-w-80">{member.email}</td>
            <td className="p-2 2xl:p-4 text-sm text-black font-medium leading-tight max-w-80">
                <span className={`w-10 ${step[member.etapa_cadastro].color} text-white px-3 py-1`}>{step[member.etapa_cadastro].label}</span>
            </td>
            <td className="p-2 2xl:p-4 xl:pl-0 text-left text-xs 2xl:text-sm font-medium">
                {member.conferido ? (
                    <span className="w-10 bg-green-500 text-white px-3.5 py-1">Sim</span>
                ) : <span className="w-10 bg-neutral-400 text-white px-3 py-1">Não</span>
                }
            </td>
            <td className="p-2 2xl:p-4 xl:pl-0 text-left text-xs 2xl:text-sm font-medium">
                {member.aprovado_bloqueado ? (
                    <span className="w-10 bg-green-500 text-white px-3.5 py-1">Sim</span>
                ) : <span className="w-10 bg-neutral-400 text-white px-3 py-1">Não</span>
                }
            </td>
            <td className="p-2 2xl:p-4 xl:pl-0 text-left text-xs 2xl:text-sm font-medium">
                {member.confirmado ? (
                    <span className="w-10 bg-green-500 text-white px-3.5 py-1">Sim</span>
                ) : <span className="w-10 bg-neutral-400 text-white px-3 py-1">Não</span>
                }
                
            </td>
            <td className="p-2 2xl:p-4 xl:pl-0 text-left text-xs 2xl:text-sm font-medium">{member.ultimo_acesso_ha}</td>
            <td className="p-2 pr-0 text-left 1xl:p-4 xl:pr-0 text-xs 1xl:text-sm">
                <div className="flex items-center gap-2">
                    <Link to={`/manager/participante/editar/${member.id}`} className="bg-primary w-8 h-8 rounded-full flex items-center justify-center duration-200 ease-in-out hover:bg-opacity-80">
                        <FontAwesomeIcon icon={faEdit} className="text-white ml-[1px] mb-[1px]" />
                    </Link>
                </div>
            </td>
        </tr>
    );
}

export default MemberItem;

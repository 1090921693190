import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes, faFile, faImage } from '@fortawesome/free-solid-svg-icons';

const FileDropzone = ({ name, value, label, onChange, onDelete, disabled }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [preview, setPreview] = useState(null);

    const handleDrag = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (e.type === "dragenter" || e.type === "dragover") {
            setIsDragging(true);
        } else if (e.type === "dragleave") {
            setIsDragging(false);
        }
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    
        const file = e.dataTransfer.files[0];
        
        if (file && isValidFileType(file)) {
            handleFile(file);
        }
    }, []);

    const isValidFileType = (file) => {
        const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        return validTypes.includes(file.type);
    };

    const handleFile = (file) => {
        if (file && isValidFileType(file)) {
            if (file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPreview(reader.result);
                };
                reader.readAsDataURL(file);
            } else {
                setPreview(null);
            }
            onChange(file);
        }
    };

    const handleChange = (e) => {
        const file = e.target.files[0];
        if (file) handleFile(file);
    };

    const handleDelete = () => {
        setPreview(null);
        onDelete();
    };

    return (
        <div className="w-full">
            <label className="block text-sm mb-1">{label}:</label>
            {!value ? (
                <div
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                    className={`relative border-2 border-dashed rounded-lg p-4 text-center cursor-pointer transition-colors ${
                        isDragging ? 'border-secondary bg-secondary bg-opacity-10' : 'border-gray-300'
                    } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    <input
                        type="file"
                        accept=".jpg,.jpeg,.png,.pdf"
                        onChange={handleChange}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                        disabled={disabled}
                    />
                    <div className="flex flex-col items-center gap-2">
                        <FontAwesomeIcon 
                            icon={faUpload} 
                            className="w-8 h-8 text-gray-400" 
                        />
                        <p className="text-sm text-gray-500">Arraste um arquivo ou clique para selecionar</p>
                        <p className="text-xs text-gray-400">JPG, JPEG, PNG ou PDF</p>
                    </div>
                </div>
            ) : (
                <div className="relative border rounded-lg p-4">
                    <div className="flex items-center gap-3">
                        {preview ? (
                            <img src={preview} alt="Preview" className="w-16 h-16 object-cover rounded" />
                        ) : (
                            <FontAwesomeIcon 
                                icon={faFile} 
                                className="w-16 h-16 text-gray-400" 
                            />
                        )}

                        <div className="flex-1">
                            <p className="text-sm font-medium truncate">{value.name}</p>
                            <p className="text-xs text-gray-500">{(value.size / 1024 / 1024).toFixed(2)} MB</p>
                        </div>
                        
                        {!disabled && (
                            <button
                                onClick={handleDelete}
                                className="p-1 hover:bg-gray-100 rounded-full"
                                type="button"
                            >
                                <FontAwesomeIcon 
                                    icon={faTimes} 
                                    className="w-5 h-5 text-gray-500" 
                                />
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FileDropzone;
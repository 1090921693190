import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEye } from '@fortawesome/free-solid-svg-icons';

import AdminLayout from '../../Layouts/AdminLayout';

import MemberList from '../../Components/Manager/MemberList';

function App() {
    return (
        <AdminLayout>
            <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                <div className="mt-4 grid grid-cols-12 gap-4 md:mt-4 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                    <div className="col-span-12">
                        <div className="rounded-sm border border-stroke bg-white px-5 pb-2.5 pt-6 shadow sm:px-7.5 xl:pb-1">
                            <div className="flex items-center mb-6">
                                <h4 className="xl:text-xl font-bold text-black">
                                    Participantes Cadastrados
                                </h4>

                                <Link to="/manager/participante/adicionar" className="flex items-center justify-center text-sm xl:text-base bg-primary text-white ml-auto px-2 xl:px-4 h-9 rounded transition-all hover:bg-green-600">
                                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                    Adicionar Participante
                                </Link>

                                <Link reloadDocument to="/manager/participantes?etapa_cadastro=concluido&aprovado_bloqueado=false" onClick={() => window.location.reload()} className="flex items-center justify-center text-sm xl:text-base bg-primary text-white ml-4 px-2 xl:px-4 h-9 rounded transition-all hover:bg-green-600">
                                    <FontAwesomeIcon icon={faEye} className="mr-2" />
                                    Visualizar completos/não aprovados
                                </Link>
                            </div>

                            <div className="flex flex-col overflow-x-auto whitespace-nowrap">
                                <MemberList />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}

export default App;
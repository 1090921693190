import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom';

import MenuItem from '../Components/MenuItem';


function MenuAside() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const navigate = useNavigate();
    const location = useLocation().pathname;

    const menus = [
        { 
            label: 'Dados Cadastrais', 
            href: '/painel/dados-cadastrais', 
            locations: ['/painel/dados-cadastrais'] 
        },
        { 
            label: 'Upload de Documentos', 
            href: '/painel/upload-documentos', 
            locations: ['/painel/upload-documentos'] 
        }
    ];

    const activeIndex = menus.findIndex(menu =>
        menu.locations.some(loc => 
            typeof loc === 'string' ? loc === location : loc.test(location)
        )
    );

    const accessToken = localStorage.getItem('access_token');
    const baseUrl = process.env.REACT_APP_API_URL;

    const handleLogout = async () => {
        try {
            const response = await fetch(`${baseUrl}/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (response.ok) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('user_id');
                localStorage.removeItem('user_type');

                navigate('/login');
            }
        } catch (error) {
            console.error("Erro ao fazer logout:", error);
        }
    };

    return (
        <aside className="z-9999 overflow-y-hidden duration-300 ease-linear">
            <div className={`fixed top-0 left-0 z-[2] bg-black w-full h-full transition-opacity duration-500 ${isMenuOpen ? 'opacity-50 pointer-events-auto' : 'opacity-0 pointer-events-none'}`} onClick={() => {setIsMenuOpen(false)}} />
            <div className="fixed top-0 left-0 w-full h-24 z-[1] flex items-center justify-center bg-primary lg:hidden -z-[1]">
                <Link to="/">
                    <img src={`/assets/img/experience-logo.png`} className="max-w-40" alt="Logo" />
                </Link>
            </div>

            <button className="fixed z-[20] top-12 right-5 transform -translate-y-1/2 lg:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <div className="flex items-center">
                    <div className="relative w-5 h-[14px]">
                        <div
                            className={`absolute top-0 bg-white h-0.5 w-5 transition-all duration-300 ${isMenuOpen ? 'rotate-45 !top-[6px]' : ''}`}
                            style={{
                                transitionDelay: isMenuOpen ? '0ms, 400ms' : '0ms',
                                transitionProperty: 'top, transform'
                            }}
                        ></div>
                        <div
                            className={`absolute top-[6px] bg-white h-0.5 w-5 transition-all duration-300 ${isMenuOpen ? 'scale-x-0 !top-[6px]' : ''}`}
                            style={{
                                transitionDelay: isMenuOpen ? '0ms, 400ms' : '0ms',
                                transitionProperty: 'top, transform'
                            }}
                        ></div>
                        <div
                            className={`absolute bottom-0 bg-white h-0.5 w-5 transition-all duration-300 ${isMenuOpen ? '-rotate-45 bottom-[6px]' : ''}`}
                            style={{
                                transitionDelay: isMenuOpen ? '0ms, 400ms' : '0ms',
                                transitionProperty: 'bottom, transform'
                            }}
                        ></div>
                    </div>
                </div>
            </button>

            <div className={`fixed lg:static z-[3] left-0 top-0 z-[10] flex h-screen flex-col bg-primary w-96 2xl:w-[425px] ${isMenuOpen ? '' : 'transform -translate-x-full'} lg:!translate-x-0 transition-all duration-500`}>
                <div className="flex items-center justify-between gap-2 px-10 lg:px-14 2xl:px-20 py-10 lg:py-14">
                    <Link to="/">
                        <img src={`/assets/img/todeschini-logo.png`} className="w-3/5 lg:w-60 mb-7" alt="Logo Brand" />
                        <img src={`/assets/img/experience-logo.png`} className="w-3/5 lg:w-[205px]" alt="Logo" />
                    </Link>

                    <button className="block lg:hidden">
                        <svg className="fill-current" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z" fill=""></path>
                        </svg>
                    </button>
                </div>


                <div className="no-scrollbar flex flex-col overflow-y-auto h-full max-h-[600px] pb-20 duration-300 ease-linear">
                    <nav className="mt-5 px-4 py-4 lg:mt-8 lg:px-6">
                        <div>
                            <ul className="mb-6 flex flex-col gap-10">
                                {menus.map((menu, index) => (
                                    <MenuItem
                                        key={index}
                                        index={index}
                                        label={menu.label}
                                        location={location}
                                        locations={menu.locations}
                                        to={menu.href}
                                        completed={index < activeIndex}
                                        last={index === menus.length - 1}
                                    />
                                ))}
                            </ul>
                        </div>
                    </nav>

                    <Link className="w-fit text-sm lg:text-base text-white font-medium underline ml-20 mb-10 lg:mb-16 mt-auto" to="/painel/contato">CONTATO e SERVIÇOS ADICIONAIS</Link>
                    <button type="button" className="flex items-center w-fit gap-2 text-white opacity-30 transition-all ml-20 hover:opacity-50" onClick={handleLogout}>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20" className="fill-white">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect width="20" height="20" transform="translate(241 991)" strokeWidth="1" />
                                </clipPath>
                            </defs>
                            
                            <g transform="translate(-241 -991)" clipPath="url(#clip-path)">
                                <path d="M6.889-12.667a2.14,2.14,0,0,1-1.569-.653,2.14,2.14,0,0,1-.653-1.569V-30.444a2.14,2.14,0,0,1,.653-1.569,2.14,2.14,0,0,1,1.569-.653h7.778v2.222H6.889v15.556h7.778v2.222Zm12.222-4.444-1.528-1.611,2.833-2.833H11.333v-2.222h9.083l-2.833-2.833,1.528-1.611,5.556,5.556Z" transform="translate(236.333 1023.667)" fill="#e8eaed" />
                            </g>
                        </svg>
                        <span className="text-small font-medium underline">Sair</span>
                    </button>
                </div>
            </div>
        </aside>
    );
}

export default MenuAside;
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import DefaultLayout from '../Layouts/DefaultLayout';

import FormDocs from '../Components/FormDocs';
import { NotificationMessage } from '../Components/NotificationMessage';

function App() {
    const [notification, setNotification] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (location.state?.message) {
            setNotification({
                ...location.state.message,
                show: true,
            });

            window.history.replaceState({}, document.title);
        }
    }, [location.state]);

    return (
        <DefaultLayout>
            <div className="relative">
                <img src={`/assets/img/header-bg.jpg`} className="h-36 lg:h-auto w-full object-cover" alt="Imagem Topo" />

                <div className="absolute inset-0 bg-black opacity-40" />
                <h2 className="absolute left-1/2 bottom-1/2 lg:left-24 lg:bottom-24 text-3xl text-white font-semibold whitespace-nowrap transform max-lg:-translate-x-1/2 max-lg:translate-y-1/2">Upload de documentos</h2>
            </div>

            <div className="mx-auto max-w-screen-2xl p-4 md:p-8 2xl:py-6 2xl:px-20">
                <div className="mt-4 grid grid-cols-12 gap-4 md:mt-4 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                    <div className="col-span-12">
                        <div className="px-5 pb-2.5 pt-6 sm:px-7.5 xl:pb-1">
                            <h4 className="mb-6 text-xl font-bold text-black">
                                
                            </h4>

                            <div className="flex flex-col">
                                <FormDocs />
                            </div>
                        </div>
                    </div>
                </div>
            </div>        

            {notification && notification.show && (
                <NotificationMessage
                    type={notification.type}
                    message={notification.text}
                    show={notification.show}
                    onClose={() => setNotification({show: false})}
                />
            )}
        </DefaultLayout>
    );
}

export default App;
import AuthLayout from '../Layouts/AuthLayout';

import FormLogin from '../Components/FormLogin';

function App() {
    return (
        <AuthLayout>
            <div className="mx-auto w-full max-w-[480px]">
                <div className="text-center">
                    <div
                        className="mx-auto mb-8 lg:mb-12 flex flex-col"
                    >
                        <img
                            src={`/assets/img/todeschini-logo.png`}
                            alt="logo"
                            className="block max-lg:w-3/4 mb-5 mx-auto pl-[9%]"
                        />

                        <img
                            src={`/assets/img/experience-logo.png`}
                            alt="logo"
                            className="block w-2/5 lg:w-1/2 max-w-[206px] mx-auto"
                        />
                    </div>

                    <FormLogin />
                </div>
            </div>
        </AuthLayout>
    );
}

export default App;
import { useState, useEffect } from "react";

function AuthLayout({ children }) {
    const [loading, setLoading] = useState(true);
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setFadeOut(true);
            setTimeout(() => setLoading(false), 300);
        }, 600);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="relative overflow-hidden px-4 sm:px-8 bg-cover before:content-[''] before:absolute before:inset-0 before:bg-black before:bg-opacity-70" style={{ backgroundImage: "url('/assets/img/login-bg.jpg')" }}>
            {loading ? (
                <div className={`fixed left-0 top-0 z-[999999] flex h-screen w-screen items-center justify-center bg-white transition-opacity duration-300 ${fadeOut ? "opacity-0" : "opacity-100"}`}>
                    <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-secondary border-t-transparent"></div>
                </div>
            ) : (
                <div className="relative flex h-screen flex-col items-center justify-center overflow-hidden">
                    <div className="no-scrollbar overflow-y-auto w-full py-10 lg:py-20">
                        {children}
                    </div>
                </div>
            )}
        </div>
    );
}

export default AuthLayout;
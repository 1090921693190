import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import AdminLayout from '../../Layouts/AdminLayout';

import { FormAddMember } from '../../Components/Manager/FormAddMember';

function App() {
    return (
        <AdminLayout>
            <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                <div className="mt-4 grid grid-cols-12 gap-4 md:mt-4 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                    <div className="col-span-12">
                        <div className="rounded-sm border border-stroke bg-white px-5 pb-2.5 pt-6 shadow sm:px-7.5 xl:pb-1">
                            <Link to="/manager/participantes" className="bg-slate-100 px-4 py-1.5 rounded border border-slate-300 float-right transition-all hover:bg-slate-200">
                                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                Voltar
                            </Link>

                            <h4 className="mb-6 text-xl font-bold text-black">
                                Adicionar participante
                            </h4>

                            <div className="flex flex-col">
                                <FormAddMember />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
}

export default App;
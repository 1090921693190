import { useState } from 'react';

function FinalModal({closeModal}) {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-[99999]">
            <div className="absolute inset-0 bg-black bg-opacity-50" onClick={closeModal}>
            </div>
            <div className="animate-fade-in-down [animation-duration:_0.1s] rounded-sm border border-stroke bg-white w-full max-w-xl px-8 py-10 text-left shadow-md relative">
                <button type="button" className="absolute top-2 right-5 opacity-40 text-xl font-bold rotate-45" onClick={closeModal}>+</button>

                <h1 className="text-secondary font-bold text-2xl mb-5">Cadastro efetuado!</h1>
                <p className="text-lg mb-10">Aguarde contato da equipe de organização da viagem.</p>
                <button type="button" className="block w-fit mx-auto bg-secondary text-white text-lg font-bold uppercase py-2 px-10 rounded-md hover:bg-opacity-80" onClick={closeModal}>Ok</button>
            </div>
        </div>
    );
}

export default FinalModal;
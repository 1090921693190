import React, { createContext, useContext, useState, useEffect } from 'react';

const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
    const [tokenData, setTokenData] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const baseUrl = process.env.REACT_APP_API_URL;
    const accessToken = localStorage.getItem('access_token');
    const userType = localStorage.getItem('user_type');

    const checkToken = async () => {
        const endpoint = userType === 'administrador' ? '/manager/usuario/' : '/painel/usuario/';
        try {
            const response = await fetch(`${baseUrl}${endpoint}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                const data = await response.json();
                setTokenData(data);
                setIsAuthenticated(true);
            } else {
                localStorage.removeItem('access_token');
                setIsAuthenticated(false);
            }
        } catch (error) {
            setIsAuthenticated(false);
        }
    };

    useEffect(() => {
        if (accessToken) {
            checkToken();
        } else {
            setIsAuthenticated(false);
        }
    }, [accessToken]);

    return (
        <TokenContext.Provider value={{ tokenData, isAuthenticated, checkToken }}>
            {children}
        </TokenContext.Provider>
    );
};

export const useToken = () => useContext(TokenContext);

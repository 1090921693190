import MenuAside from '../Components/MenuAside';

function DefaultLayout({ children }) {
    return (
        <>
            <div className="flex h-screen overflow-hidden text-primary">
                <MenuAside />
                <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
                    <div className="App pt-24 lg:pt-0">
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
}

export default DefaultLayout;
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import FormRegister from './FormRegister';
import FormPassword from './FormPassword';

function FormLogin() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [errors, setErrors] = useState('');
    const [messages, setMessages] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            window.location.replace('/painel/dados-cadastrais');
        }
    }, [navigate]);

    const baseUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors('');

        if (!email || !senha) {
            setErrors('Por favor, preencha seu e-mail e senha.');
            setLoading(false);
            return;
        }

        const loginData = {
            email: email,
            password: senha,
            lembrar: rememberMe
        };

        try {
            const response = await fetch(`${baseUrl}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                setMessages(null);
                setErrors(errorData.message || 'Usuário ou senha incorretos');
                setLoading(false);
                return;
            }

            const data = await response.json();

            localStorage.setItem('access_token', data.token);
            localStorage.setItem('user_id', data.usuario.id);
            localStorage.setItem('user_type', data.usuario.funcao);

            window.location.replace('/painel/dados-cadastrais');
        } catch (e) {
            setMessages(null);
            setErrors('Erro ao conectar com o servidor. Tente novamente.');
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const openRegisterModal = () => {
        setIsRegisterModalOpen(true);
    };

    const closeRegisterModal = () => {
        setIsRegisterModalOpen(false);
    };


    const openPasswordModal = () => {
        setIsPasswordModalOpen(true);
    };

    const closePasswordModal = () => {
        setIsPasswordModalOpen(false);
    };

    useEffect(() => {
        if (location.state?.message) {
            setErrors(null);
            setMessages({
                type: location.state.message.type,
                message: location.state.message.text
            });

            setTimeout(() => {
                setMessages(null);
            }, 6000);
        }
    }, [location.state]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="mb-2 ml-2 block text-left text-white">Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full rounded-lg border border-stroke py-3 lg:py-4 pl-6 pr-10 outline-none focus:border-secondary focus-visible:shadow-none"
                    />
                </div>

                <div className="mb-4">
                    <label className="mb-2 ml-2 block text-left text-white">Senha</label>
                    <input
                        type="password"
                        value={senha}
                        onChange={(e) => setSenha(e.target.value)}
                        className="w-full rounded-lg border border-stroke py-3 lg:py-4 pl-6 pr-10 outline-none focus:border-secondary focus-visible:shadow-none"
                    />
                </div>

                <div className="flex justify-between mb-10">
                    <label className="flex items-center">
                        <input 
                            type="checkbox" 
                            checked={rememberMe} 
                            onChange={(e) => setRememberMe(e.target.checked)} 
                            className="relative aspect-square w-5 h-5 cursor-pointer appearance-none rounded-full border-0 bg-secondary mr-2 after:absolute after:top-1/2 after:left-1/2 after:aspect-square after:h-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full after:bg-transparent after:checked:bg-white checked:bg-none checked:text-white focus:ring-offset-0 focus:ring-0"
                        />
                        <span className="text-white opacity-60">Lembrar de mim</span>
                    </label>

                    <button
                        type="button"
                        className="text-sm text-white underline opacity-60 transition hover:opacity-75"
                        onClick={openPasswordModal}
                    >
                        Esqueceu sua senha?
                    </button>
                </div>

                {
                    errors && (
                        <div className="-mt-4 mb-6">
                            <div className="flex items-center justify-center w-full border-l-4 border-rose-500 bg-rose-100 px-7 py-4 shadow-md md:p-5">
                                <div className="mr-5 flex h-9 w-full max-w-[36px] items-center justify-center rounded-lg bg-rose-500">
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path 
                                          d="M6.4917 7.65579L11.106 12.2645C11.2545 12.4128 11.4715 12.5 11.6738 12.5C11.8762 12.5 12.0931 12.4128 12.2416 12.2645C12.5621 11.9445 12.5623 11.4317 12.2423 11.1114C12.2422 11.1113 12.2422 11.1113 12.2422 11.1113C12.242 11.1111 12.2418 11.1109 12.2416 11.1107L7.64539 6.50351L12.2589 1.91221L12.2595 1.91158C12.5802 1.59132 12.5802 1.07805 12.2595 0.757793C11.9393 0.437994 11.4268 0.437869 11.1064 0.757418C11.1063 0.757543 11.1062 0.757668 11.106 0.757793L6.49234 5.34931L1.89459 0.740581L1.89396 0.739942C1.57364 0.420019 1.0608 0.420019 0.740487 0.739944C0.42005 1.05999 0.419837 1.57279 0.73985 1.89309L6.4917 7.65579ZM6.4917 7.65579L1.89459 12.2639L1.89395 12.2645C1.74546 12.4128 1.52854 12.5 1.32616 12.5C1.12377 12.5 0.906853 12.4128 0.758361 12.2645L1.1117 11.9108L0.758358 12.2645C0.437984 11.9445 0.437708 11.4319 0.757539 11.1116C0.757812 11.1113 0.758086 11.111 0.75836 11.1107L5.33864 6.50287L0.740487 1.89373L6.4917 7.65579Z" 
                                          fill="#ffffff" 
                                          stroke="#ffffff"
                                        />
                                    </svg>
                                </div>

                                <div className="w-full">
                                    <h5 className="text-left text-rose-800 text-opacity-80 font-medium">{errors}</h5>
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    messages && (
                        <div className="-mt-4 mb-6">
                            <div
                                className={`flex items-center justify-center w-full border-l-4 px-7 py-4 shadow-md md:p-5 ${
                                    messages.type === 'success'
                                        ? 'border-green-500 bg-green-100'
                                        : 'border-rose-500 bg-rose-100'
                                }`}
                            >
                                <div
                                    className={`mr-5 flex h-9 w-full max-w-[36px] items-center justify-center rounded-lg ${
                                        messages.type === 'success'
                                            ? 'bg-green-500'
                                            : 'bg-rose-500'
                                    }`}
                                >
                                    <svg
                                        width="13"
                                        height="13"
                                        viewBox="0 0 13 13"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6.4917 7.65579L11.106 12.2645C11.2545 12.4128 11.4715 12.5 11.6738 12.5C11.8762 12.5 12.0931 12.4128 12.2416 12.2645C12.5621 11.9445 12.5623 11.4317 12.2423 11.1114C12.2422 11.1113 12.2422 11.1113 12.2422 11.1113C12.242 11.1111 12.2418 11.1109 12.2416 11.1107L7.64539 6.50351L12.2589 1.91221L12.2595 1.91158C12.5802 1.59132 12.5802 1.07805 12.2595 0.757793C11.9393 0.437994 11.4268 0.437869 11.1064 0.757418C11.1063 0.757543 11.1062 0.757668 11.106 0.757793L6.49234 5.34931L1.89459 0.740581L1.89396 0.739942C1.57364 0.420019 1.0608 0.420019 0.740487 0.739944C0.42005 1.05999 0.419837 1.57279 0.73985 1.89309L6.4917 7.65579ZM6.4917 7.65579L1.89459 12.2639L1.89395 12.2645C1.74546 12.4128 1.52854 12.5 1.32616 12.5C1.12377 12.5 0.906853 12.4128 0.758361 12.2645L1.1117 11.9108L0.758358 12.2645C0.437984 11.9445 0.437708 11.4319 0.757539 11.1116C0.757812 11.1113 0.758086 11.111 0.75836 11.1107L5.33864 6.50287L0.740487 1.89373L6.4917 7.65579Z"
                                            fill="#ffffff"
                                            stroke="#ffffff"
                                        />
                                    </svg>
                                </div>

                                <div className="w-full">
                                    <h5
                                        className={`text-left font-medium ${
                                            messages.type === 'success'
                                                ? 'text-green-800 text-opacity-80'
                                                : 'text-rose-800 text-opacity-80'
                                        }`}
                                    >
                                        {messages.message}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="flex gap-8 mb-5">
                    <button
                        type="button"
                        className="w-7/12 cursor-pointer rounded-lg border-2 border-secondary bg-black bg-opacity-0 p-2 lg:text-xl text-white uppercase transition hover:bg-opacity-15"
                        onClick={openRegisterModal}
                    >
                        Criar minha conta
                    </button>

                    <button
                        className="w-5/12 cursor-pointer rounded-lg border-2 border-white bg-white p-2 lg:text-xl text-secondary uppercase transition hover:bg-neutral-200 hover:border-neutral-200"
                    >
                        {!loading ? (
                            'Entrar'
                        ) : (
                            <div role="status" className="flex justify-center items-center">
                                <svg aria-hidden="true" className="w-6 h-6 text-black text-opacity-20 animate-spin fill-secondary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                    </button>
                </div>
            </form>

            {isRegisterModalOpen && <FormRegister closeModal={closeRegisterModal} />}

            {isPasswordModalOpen && <FormPassword closeModal={closePasswordModal} />}
        </>
    );
}

export default FormLogin;
import React, { useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

const ReportGeneralPrint = React.forwardRef(({ members, selectedDestiny }, ref) => {
    return (
        <div ref={ref} className="hidden print:block m-8">
            <h1 className="text-2xl font-bold mb-4">Relatório Geral - {selectedDestiny.label}</h1>
            <table className="w-full border-collapse">
                <thead>
                    <tr className="">
                        <th className="py-2 px-1 text-left text-xs font-normal uppercase">Nome</th>
                        <th className="py-2 px-1 text-left text-xs text-center font-normal uppercase">Nascimento</th>
                        <th className="py-2 px-1 text-left text-xs text-center font-normal uppercase">Fone</th>
                        <th className="py-2 px-1 text-left text-xs text-center font-normal uppercase">Num. Pass.</th>
                        <th className="py-2 px-1 text-left text-xs text-center font-normal uppercase">Venc. Pass.</th>
                        <th className="py-2 px-1 text-left text-xs text-center font-normal uppercase">Rest. Alimentar</th>
                        <th className="py-2 px-1 text-left text-xs text-center font-normal uppercase">Limitação</th>
                        <th className="py-2 px-1 text-left text-xs text-center font-normal uppercase">Medicação</th>
                        <th className="py-2 px-1 text-left text-xs text-center font-normal uppercase">Prob Saúde</th>
                        <th className="py-2 px-1 text-left text-xs text-center font-normal uppercase">Situação</th>
                    </tr>
                </thead>
                <tbody>
                    {members.map((member, index) => (
                        <tr key={index} className="border-b border-gray-300">
                            <td className="py-2 px-1 text-[10px] uppercase whitespace-nowrap">{member.nome_completo}</td>
                            <td className="py-2 px-1 text-[10px] text-center">{member.data_nascimento}</td>
                            <td className="py-2 px-1 text-[10px] text-center">{member.fone_celular}</td>
                            <td className="py-2 px-1 text-[10px] text-center">{member.numero_passaporte}</td>
                            <td className="py-2 px-1 text-[10px] text-center">{member.data_validade_passaporte}</td>
                            <td className="py-2 px-1 text-[10px] text-center">{member.restricao_alimentar}</td>
                            <td className="py-2 px-1 text-[10px] text-center">{member.limitacao}</td>
                            <td className="py-2 px-1 text-[10px] text-center">{member.medicamento}</td>
                            <td className="py-2 px-1 text-[10px] text-center">{member.problema_saude}</td>
                            <td className="py-2 px-1 text-[10px] text-center">
                                {member.situacao ? (
                                    <FontAwesomeIcon icon={faCheck} className="mr-2 text-lg text-green-500" />
                                ) : (
                                    <FontAwesomeIcon icon={faXmark} className="mr-2 text-lg text-red-500" />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
});

export default ReportGeneralPrint;
import { useState, useEffect } from 'react';

import FileDropzone from './FileDropzone';
import FinalModal from './FinalModal';

function FormDocs() {
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({
        numero: '',
        paginas_em_branco: '',
        data_emissao: '',
        data_validade: '',
        passaporte: '',
        certificado: '',
        aprovado_bloqueado: false,
        upload_concluido: false
    });

    const [userErrorData, setUserErrorData] = useState({
        numero: [],
        paginas_em_branco: [],
        data_emissao: [],
        data_validade: [],
        passaporte: [],
        certificado: [],
    });

    const [formLoading, setFormLoading] = useState(false);
    const [error, setError] = useState('');

    const [notification, setNotification] = useState({
        type: 'info',
        message: '',
        show: false,
    });

    const accessToken = localStorage.getItem('access_token');
    const baseUrl = process.env.REACT_APP_API_URL;

    const fetchUserDocs = async () => {
        try {
            const response = await fetch(`${baseUrl}/painel/usuario/documentos`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error('Erro ao buscar o usuário.');
            }

            const data = await response.json();
            setUserData(data.documento);

        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserDocs();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (userData.upload_concluido) {
            return;
        }

        setFormLoading(true);
        try {
            const formData = new FormData();
        
            Object.keys(userData).forEach(key => {
                if (key !== 'passaporte' && key !== 'certificado') {
                    formData.append(key, userData[key]);
                }
            });

            if (userData.passaporte) {
                formData.append('passaporte', userData.passaporte);
            }
            if (userData.certificado) {
                formData.append('certificado', userData.certificado);
            }

            const response = await fetch(`${baseUrl}/painel/usuario/documentos/enviar`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                body: formData
            });

            if (response.ok) {
                setFormLoading(false);
                
                setNotification({
                    show: true,
                });

                setLoading(true);
                fetchUserDocs();
                setLoading(false);
            } else {
                if (response.status === 422) {
                    const errors = await response.json();

                    setFormLoading(false);
                    setUserErrorData(errors);
                    setError('Erro ao atualizar o usuário');
                }
            }
        } catch (error) {
            setFormLoading(false);
            setError(error.message);
        }
    };

    const handleCloseNotification = () => {
        setNotification((prev) => ({ ...prev, show: false }));
    };

    if (loading) return (
        <div className="mx-auto py-10">
            <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
        </div>
    );

    return (
        <>
            <form onSubmit={handleSubmit} className="max-w-5xl pb-10">
                <div className="mb-6 2xl:mb-10">
                    <h4 className="flex gap-6 items-center font-secondary text-md font-bold whitespace-nowrap mb-3 2xl:mb-5">Documentos <span className="w-full border-t" /></h4>

                    <h5 className="max-lg:text-sm 2xl:mb-1">Utilize os campos para realizar o upload do seu passaporte</h5>
                    <h5 className="max-lg:text-sm">Os documentos escaneados devem coincidir com os dados informados no setor de cadastro:</h5>
                </div>

                <div className="mb-6 2xl:mb-10">
                    <h4 className="flex gap-6 items-center font-secondary text-md font-bold whitespace-nowrap mb-3 2xl:mb-5">Passaporte <span className="w-full border-t" /></h4>
                    <div className="mb-3 flex gap-3 lg:gap-5 flex-col lg:flex-row">
                        <div className="w-full lg:w-1/5">
                            <label className="block text-sm mb-1">Número do passaporte:</label>
                            <input
                                type="text"
                                name="numero"
                                value={userData.numero}
                                onClick={() => setUserErrorData(prevData => ({...prevData, numero: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.numero && userErrorData.numero[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.numero[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, numero: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>

                        <div className="w-full lg:w-1/5">
                            <label className="block text-sm mb-1">Páginas em branco:</label>
                            <input
                                type="number"
                                name="paginas_em_branco"
                                min="0"
                                max="100"
                                value={userData.paginas_em_branco}
                                onClick={() => setUserErrorData(prevData => ({...prevData, paginas_em_branco: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.paginas_em_branco && userErrorData.paginas_em_branco[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.paginas_em_branco[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, paginas_em_branco: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>

                        <div className="w-full lg:w-1/5">
                            <label className="block text-sm mb-1">Data de Emissão:</label>
                            <input
                                type="date"
                                name="data_emissao"
                                value={userData.data_emissao}
                                onClick={() => setUserErrorData(prevData => ({...prevData, data_emissao: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.data_emissao && userErrorData.data_emissao[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.data_emissao[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, data_emissao: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>

                        <div className="w-full lg:w-1/5">
                            <label className="block text-sm mb-1">Data de Validade:</label>
                            <input
                                type="date"
                                name="data_validade"
                                value={userData.data_validade}
                                onClick={() => setUserErrorData(prevData => ({...prevData, data_validade: []}))}
                                onChange={handleChange}
                                className="w-full px-3 py-1.5 2xl:py-2 border border-gray-300 rounded-md outline-none focus:border-secondary focus-visible:shadow-none"
                                disabled={userData.aprovado_bloqueado}
                            />
                            {userErrorData.data_validade && userErrorData.data_validade[0] && (<span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">{userErrorData.data_validade[0]} <span onClick={() => setUserErrorData(prevData => ({...prevData, data_validade: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span></span>)}
                        </div>
                    </div>
                </div>

                <div className="bg-secondary w-fit px-4 pt-2 pb-4 mb-10 rounded-md">
                    <h4 className="text-white font-bold">Aviso!</h4>

                    <h5 className="text-white text-sm">
                        Os uploads devem ser feitos de documentos originais e com boa qualidade.<br/>
                        Utilize apenas as extensões: <b>JPG</b>, <b>JPEG</b>, <b>PNG</b> ou <b>PDF</b>.
                    </h5>
                </div>

                <div className="mb-6 2xl:mb-10">
                    <h4 className="flex gap-6 items-center font-secondary text-md font-bold whitespace-nowrap mb-3 2xl:mb-5">Uploads <span className="w-full border-t" /></h4>
                    <div className="relative mb-3 flex gap-3 lg:gap-5 flex-col lg:flex-row">
                        <div className="w-full lg:w-1/2">
                            {userData.upload_concluido && userData.passaporte ? (
                                <>
                                    <label className="block text-sm mb-1">Passaporte:</label>
                                    <div className="p-1 border border-gray-300 rounded-md">
                                        {userData.passaporte.endsWith('.pdf') ? (
                                            <iframe
                                                src={userData.passaporte}
                                                className="w-full h-96"
                                                title="Passaporte PDF"
                                            ></iframe>
                                        ) : (
                                            <img
                                                src={userData.passaporte}
                                                className="w-full"
                                                alt="Passaporte"
                                            />
                                        )}
                                    </div>
                                </>
                            ) : 
                                <FileDropzone
                                    name="passaporte"
                                    value={userData.passaporte}
                                    label="Passaporte"
                                    onChange={(file) => {
                                        setUserData(prev => ({...prev, passaporte: file}));
                                        setUserErrorData(prevData => ({...prevData, passaporte: []}));
                                    }}
                                    onDelete={() => setUserData(prev => ({...prev, passaporte: null}))}
                                    disabled={userData.upload_concluido}
                                />
                            }

                            {userErrorData.passaporte && userErrorData.passaporte[0] && (
                                <span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">
                                    {userErrorData.passaporte[0]} 
                                    <span onClick={() => setUserErrorData(prevData => ({...prevData, passaporte: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span>
                                </span>
                            )}
                        </div>

                        {userData.exigir_certificado && (
                            <div className="w-full lg:w-1/2">
                                {userData.upload_concluido && userData.certificado ? (
                                    <>
                                        <label className="block text-sm mb-1">Certificado Internacional de Vacinação - Febre Amarela:</label>
                                        <div className="p-1 border border-gray-300 rounded-md">
                                            {userData.passaporte.endsWith('.pdf') ? (
                                                <iframe
                                                    src={userData.certificado}
                                                    className="w-full h-96"
                                                    title="Certificado PDF"
                                                ></iframe>
                                            ) : (
                                                <img
                                                    src={userData.certificado}
                                                    className="w-full"
                                                    alt="Certificado"
                                                />
                                            )}
                                        </div>
                                    </>
                                ) : 
                                    <FileDropzone
                                        name="certificado"
                                        value={userData.certificado}
                                        label="Certificado"
                                        onChange={(file) => {
                                            setUserData(prev => ({...prev, certificado: file}));
                                            setUserErrorData(prevData => ({...prevData, certificado: []}));
                                        }}
                                        onDelete={() => setUserData(prev => ({...prev, certificado: null}))}
                                        disabled={userData.upload_concluido}
                                    />
                                }
                                {userErrorData.certificado && userErrorData.certificado[0] && (
                                    <span className="flex justify-between border-b border-red-500 text-xs 2xl:text-sm text-red-500 tracking-tight 2xl:tracking-normal mt-1 pb-1 pl-2">
                                        {userErrorData.certificado[0]} 
                                        <span onClick={() => setUserErrorData(prevData => ({...prevData, certificado: []}))} className="rotate-45 -my-2 p-2 cursor-pointer">+</span>
                                    </span>
                                )}
                            </div>
                        )}

                        {!userData.numero && (
                            <div className="absolute flex items-center justify-center inset-0 bg-white text-lg bg-opacity-80 p-6">
                                <h5 className="text-secondary font-bold text-center">Finalize a primeira etapa do seu cadastro!</h5>
                            </div>
                        )}
                    </div>
                </div>

                {userData.numero && (
                <button
                        type="submit"
                        className="relative flex items-center justify-center bg-white text-secondary font-bold uppercase py-2 px-6 border-2 border-secondary rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={userData.upload_concluido}
                    >
                        {!formLoading ? (
                            <>
                                <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="28" height="28" viewBox="0 0 28 28">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Retângulo_563" data-name="Retângulo 563" width="28" height="28" transform="translate(1293 893)" fill="#c9002e" stroke="#707070" strokeWidth="1"/>
                                        </clipPath>
                                    </defs>
                                    <g id="Grupo_de_máscara_1" data-name="Grupo de máscara 1" transform="translate(-1293 -893)">
                                        <path id="check_circle_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24" d="M14.84-10.36l9.87-9.87-1.96-1.96-7.91,7.91-3.99-3.99L8.89-16.31ZM16.8-2.8a13.633,13.633,0,0,1-5.46-1.1A14.138,14.138,0,0,1,6.895-6.895,14.138,14.138,0,0,1,3.9-11.34,13.633,13.633,0,0,1,2.8-16.8a13.633,13.633,0,0,1,1.1-5.46A14.138,14.138,0,0,1,6.895-26.7,14.138,14.138,0,0,1,11.34-29.7a13.633,13.633,0,0,1,5.46-1.1,13.633,13.633,0,0,1,5.46,1.1A14.138,14.138,0,0,1,26.7-26.7,14.138,14.138,0,0,1,29.7-22.26a13.633,13.633,0,0,1,1.1,5.46,13.633,13.633,0,0,1-1.1,5.46A14.138,14.138,0,0,1,26.7-6.895,14.138,14.138,0,0,1,22.26-3.9,13.633,13.633,0,0,1,16.8-2.8Zm0-2.8a10.809,10.809,0,0,0,7.945-3.255A10.809,10.809,0,0,0,28-16.8a10.809,10.809,0,0,0-3.255-7.945A10.809,10.809,0,0,0,16.8-28a10.809,10.809,0,0,0-7.945,3.255A10.809,10.809,0,0,0,5.6-16.8,10.809,10.809,0,0,0,8.855-8.855,10.809,10.809,0,0,0,16.8-5.6ZM16.8-16.8Z" transform="translate(1290.2 923.8)" fill="#c9002e"/>
                                    </g>
                                </svg>
                                Finalizar Cadastro
                            </>
                        ) : (
                            <>
                                <div role="status" className="absolute inset-0 flex justify-center items-center">
                                    <svg aria-hidden="true" className="w-6 h-6 text-secondary text-opacity-60 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                                <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="28" height="28" viewBox="0 0 28 28" className="opacity-0">
                                    <defs>
                                        <clipPath id="clip-path">
                                            <rect id="Retângulo_563" data-name="Retângulo 563" width="28" height="28" transform="translate(1293 893)" fill="#c9002e" stroke="#707070" strokeWidth="1"/>
                                        </clipPath>
                                    </defs>
                                    <g id="Grupo_de_máscara_1" data-name="Grupo de máscara 1" transform="translate(-1293 -893)">
                                        <path id="check_circle_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24" d="M14.84-10.36l9.87-9.87-1.96-1.96-7.91,7.91-3.99-3.99L8.89-16.31ZM16.8-2.8a13.633,13.633,0,0,1-5.46-1.1A14.138,14.138,0,0,1,6.895-6.895,14.138,14.138,0,0,1,3.9-11.34,13.633,13.633,0,0,1,2.8-16.8a13.633,13.633,0,0,1,1.1-5.46A14.138,14.138,0,0,1,6.895-26.7,14.138,14.138,0,0,1,11.34-29.7a13.633,13.633,0,0,1,5.46-1.1,13.633,13.633,0,0,1,5.46,1.1A14.138,14.138,0,0,1,26.7-26.7,14.138,14.138,0,0,1,29.7-22.26a13.633,13.633,0,0,1,1.1,5.46,13.633,13.633,0,0,1-1.1,5.46A14.138,14.138,0,0,1,26.7-6.895,14.138,14.138,0,0,1,22.26-3.9,13.633,13.633,0,0,1,16.8-2.8Zm0-2.8a10.809,10.809,0,0,0,7.945-3.255A10.809,10.809,0,0,0,28-16.8a10.809,10.809,0,0,0-3.255-7.945A10.809,10.809,0,0,0,16.8-28a10.809,10.809,0,0,0-7.945,3.255A10.809,10.809,0,0,0,5.6-16.8,10.809,10.809,0,0,0,8.855-8.855,10.809,10.809,0,0,0,16.8-5.6ZM16.8-16.8Z" transform="translate(1290.2 923.8)" fill="#c9002e"/>
                                    </g>
                                </svg>
                                <span className="opacity-0">Finalizar Cadastro</span>
                            </>
                        )}
                    </button>
                )}
            </form>    

            {notification && notification.show && (
                <FinalModal closeModal={handleCloseNotification} />
            )}
        </>
    );
}

export default FormDocs;
import { Link } from 'react-router-dom';

function MenuItem({ index, label, icon, location, locations, to, completed, last }) {
    return (
        <li>
            <Link
                className="group relative pl-20 2xl:pl-28 block w-5/6 font-secondary lg:text-xl 2xl:text-2xl font-medium text-white duration-300 ease-in-out uppercase z-[1]"
                to={to}
            >   
                {!last && (
                    <span className={`absolute top-1/2 left-10 2xl:left-16 -ml-px w-px h-[calc(100%_+_2.5rem)] -z-[1] ${locations.some(loc => 
                    typeof loc === 'string' ? loc === location : loc.test(location)
                ) ? ' bg-neutral-800' : ' bg-green-400'}`} />
                )}

                <span className={`absolute top-1/2 left-8 2xl:left-14 w-4 h-4 rounded-full -translate-y-1/2 ${completed ? 'bg-green-400' : ''} ${locations.some(loc => 
                    typeof loc === 'string' ? loc === location : loc.test(location)
                ) ? ' bg-white' : (!last ? 'bg-green-400' : 'bg-neutral-800')}`} />

                <span className={` duration-300 ease-in-out uppercase ${locations.some(loc => 
                    typeof loc === 'string' ? loc === location : loc.test(location)
                ) ? 'group-hover:opacity-80' : 'opacity-30 group-hover:opacity-40'}`}>{label}</span>
            </Link>
        </li>
    );
}

export default MenuItem;
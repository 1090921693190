import React, { useState, useEffect, useMemo } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { parse, format } from 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheck, faEye, faPlane } from '@fortawesome/free-solid-svg-icons';

import MemberItem from './MemberItem';
import { NotificationMessage } from './NotificationMessage';

registerLocale('pt-BR', ptBR);

function MemberList() {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [estados, setEstados] = useState([]);

    const [notification, setNotification] = useState({
        type: 'info',
        message: '',
        show: false,
    });

    const [filters, setFilters] = useState({
        nome: '',
        email: '',
        etapa_cadastro: '',
        ultimo_acesso_ha: '',
        conferido: '',
        aprovado_bloqueado: '',
        confirmado: '',
    });

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [openDateRange, setOpenDateRange] = useState(false);
    const [resultsPerPage, setResultsPerPage] = useState(20);
    
    const navigate = useNavigate();
    const location = useLocation();
    
    const accessToken = localStorage.getItem('access_token');
    const baseUrl = process.env.REACT_APP_API_URL;

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        setFilters(prev => ({
            ...prev,
            dateFrom: start ? format(start, 'dd/MM/yyyy') : '',
            dateTo: end ? format(end, 'dd/MM/yyyy') : '',
        }));
    };

    const updateUrlParams = (newFilters) => {
        const filteredParams = Object.fromEntries(
            Object.entries(newFilters).filter(([_, value]) => value !== '' && value !== null)
        );

        const params = new URLSearchParams(filteredParams).toString();
        navigate(`?${params}`);
    };

    const fetchMembers = async () => {
        try {
            const accessToken = localStorage.getItem('access_token');
            const response = await fetch(`${baseUrl}/manager/participantes`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error('Erro ao buscar os participantes.');
            }

            const data = await response.json();
            
            setMembers(data.participantes);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchEstados = async () => {
        try {
            const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            if (!response.ok) {
                throw new Error('Erro ao buscar os estados.');
            }
            const data = await response.json();
            setEstados(data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        for (const param of searchParams) {
            setFilters(prev => ({
                ...prev,
                [param[0]]: param[1]
            }));
        }

        fetchMembers();
        fetchEstados();
    }, []);

    const handleCloseNotification = () => {
        setNotification((prev) => ({ ...prev, show: false }));
    };

    const handleSelectAll = (isChecked) => {
        if (isChecked) {
            setSelectedMembers(members.map(member => member.id));
        } else {
            setSelectedMembers([]);
        }
    };

    const isAllSelected = selectedMembers.length === members.length && members.length > 0;

    const handleSelectMember = (memberId) => {
        setSelectedMembers(prevSelected => 
            prevSelected.includes(memberId)
                ? prevSelected.filter(id => id !== memberId)
                : [...prevSelected, memberId]
        );
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        const updatedFilters = {
            ...filters,
            [name]: value,
        };
        setFilters(updatedFilters);
        updateUrlParams(updatedFilters);
    };

    const handleDeleteSelected = async () => {
        const idsString = selectedMembers.join(',');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/manager/participantes/excluir/${idsString}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                setNotification({
                    type: 'error',
                    message: 'Erro ao enviar o pedido ao sistema!',
                    show: true,
                });

                throw new Error('Erro ao excluir os itens.');
            }

            setNotification({
                type: 'success',
                message: 'Participantes excluídos com sucesso',
                show: true
            });

            setSelectedMembers([]);
            fetchMembers();
        } catch (error) {
            console.error('Erro ao excluir itens:', error);
        }
    };

    const handleApproveSelected = async () => {
        const idsString = selectedMembers.join(',');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/manager/participantes/aprovar/${idsString}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                setNotification({
                    type: 'error',
                    message: 'Erro ao enviar o pedido ao sistema!',
                    show: true,
                });

                throw new Error('Erro ao aprovar os itens.');
            }

            setNotification({
                type: 'success',
                message: 'Participantes aprovados com sucesso',
                show: true
            });

            setSelectedMembers([]);
            fetchMembers();
        } catch (error) {
            console.error('Erro ao aprovar itens:', error);
        }
    };

    const handleCheckSelected = async () => {
        const idsString = selectedMembers.join(',');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/manager/participantes/conferir/${idsString}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                setNotification({
                    type: 'error',
                    message: 'Erro ao enviar o pedido ao sistema!',
                    show: true,
                });

                throw new Error('Erro ao conferir os itens.');
            }

            setNotification({
                type: 'success',
                message: 'Participantes conferidos com sucesso',
                show: true
            });

            setSelectedMembers([]);
            fetchMembers();
        } catch (error) {
            console.error('Erro ao conferir itens:', error);
        }
    };

    const handleConfirmSelected = async () => {
        const idsString = selectedMembers.join(',');

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/manager/participantes/confirmar/${idsString}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                setNotification({
                    type: 'error',
                    message: 'Erro ao enviar o pedido ao sistema!',
                    show: true,
                });

                throw new Error('Erro ao confirmar os itens.');
            }

            setNotification({
                type: 'success',
                message: 'Participantes confirmados com sucesso',
                show: true
            });

            setSelectedMembers([]);
            fetchMembers();
        } catch (error) {
            console.error('Erro ao conferir itens:', error);
        }
    };

    const filteredMembers = useMemo(() => {
        return members.filter(member => {
            const matchesnome = member.nome.toLowerCase().includes(filters.nome.toLowerCase());
            const matchesemail = member.email.toLowerCase().includes(filters.email.toLowerCase());
            const matchesetapa = member.etapa_cadastro.toLowerCase().includes(filters.etapa_cadastro.toLowerCase());
            const matchesacess = member.ultimo_acesso_ha.toString().includes(filters.ultimo_acesso_ha.toString());
            const matchesconferido = member.conferido.toString().includes(filters.conferido);
            const matchesaprovado = member.aprovado_bloqueado.toString().includes(filters.aprovado_bloqueado);
            const matchesconfirmado = member.confirmado.toString().includes(filters.confirmado);

            return matchesnome && matchesemail && matchesetapa && matchesacess && matchesconferido && matchesaprovado && matchesconfirmado;
        });
    }, [members, filters]);

    const columns = useMemo(() => [
        {
            id: 'select',
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <input
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={(e) => handleSelectAll(e.target.checked)}
                />
            ),
            Cell: ({ row }) => (
                <input
                    type="checkbox"
                    checked={selectedMembers.includes(row.original.id)}
                    onChange={() => handleSelectMember(row.original.id)}
                />
            ),
            headerClassName: 'p-4 text-left text-sm font-medium uppercase'
        },
        { Header: 'Nome', accessor: 'nome', headerClassName: 'p-2 text-left 2xl:p-4 text-sm font-medium uppercase select-none' },
        { Header: 'E-mail', accessor: 'email', headerClassName: 'p-2 text-left 2xl:p-4 text-sm font-medium uppercase select-none' },
        { Header: 'Etapa', accessor: 'etap_cadastro', headerClassName: 'p-2 text-left 2xl:p-4 text-sm font-medium uppercase select-none' },
        { Header: 'Conferido', accessor: 'conferido', headerClassName: 'p-2 text-left 2xl:p-4 text-sm font-medium uppercase select-none' },
        { Header: 'Aprovado', accessor: 'aprovado_bloqueado', headerClassName: 'p-2 text-left 2xl:p-4 text-sm font-medium uppercase select-none' },
        { Header: 'Confirmado', accessor: 'confirmado', headerClassName: 'p-2 text-left 2xl:p-4 text-sm font-medium uppercase select-none' },
        { Header: 'Último acesso', accessor: 'ultimo_acesso_ha', headerClassName: 'p-2 text-left 2xl:p-4 text-sm font-medium uppercase select-none' },
        { Header: 'Ações', accessor: '', headerClassName: 'p-2 text-left 2xl:p-4 text-sm font-medium uppercase select-none' },
    ], [isAllSelected, selectedMembers]);

    const pageParams = new URLSearchParams(window.location.search);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        state: { pageIndex },
        gotoPage,
        nextPage,
        previousPage,
    } = useTable(
        {
            columns,
            data: filteredMembers,
            initialState: { pageIndex: pageParams.has('page') ? (pageParams.get('page') -1) : 0, pageSize: resultsPerPage },
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        if (location.state?.message) {
            setNotification({
                type: location.state.message.type,
                message: location.state.message.text,
                show: true,
            });
        }
    }, [location.state]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        if (pageIndex !== 0 || searchParams.has('page')) {
            searchParams.set('page', pageIndex + 1);
            navigate(`?${searchParams.toString()}`, { replace: true });
        }
    }, [pageIndex, navigate]);

    if (loading) return (
        <div className="mx-auto py-10">
            <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
        </div>
    );

    if (error) {
        return <div className="text-2xl text-center text-red-600 font-bold my-10">{error}</div>;
    }

    return (
        <>
            <table {...getTableProps()} className="w-full table-auto border-collapse bg-gray-2">
                <thead>
                    {headerGroups.map((headerGroup, index) => {
                        const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();

                        return (
                            <tr key={key || index} {...headerGroupProps}>
                                {headerGroup.headers.map((column, groupIndex) => {
                                    const { key: colKey, ...columnProps } = column.getHeaderProps(column.getSortByToggleProps({ title: undefined }));

                                    return (
                                        <th
                                            key={colKey || groupIndex}
                                            {...columnProps}
                                            className={column.headerClassName}
                                        >
                                            {column.render('Header')}
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <svg className="fill-gray-500 inline-block mb-1 ml-1" width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 0L0 5H10L5 0Z" fill=""></path>
                                                    </svg>
                                                ) : (
                                                    <svg className="fill-gray-500 inline-block mb-1 ml-1" width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 5L10 0L-4.37114e-07 8.74228e-07L5 5Z" fill=""></path>
                                                    </svg>
                                                )
                                            ) : ''}
                                        </th>
                                    );
                                })}
                            </tr>
                        );
                    })}

                    <tr className="bg-gray-50">
                        <td></td>
                        <td className="p-2 text-left 2xl:p-4 text-sm">
                            <div className="flex items-center space-x-2">
                                <input 
                                    type="text" 
                                    name="nome"
                                    placeholder="Nome Participante" 
                                    className="border border-gray-300 text-xs 2xl:text-sm p-1" 
                                    value={filters.nome}
                                    onChange={handleFilterChange}
                                />
                            </div>
                        </td>
                        <td className="p-2 text-left 2xl:p-4 text-sm">
                            <div className="flex items-center space-x-2">
                                <input 
                                    type="text" 
                                    name="email"
                                    placeholder="E-mail Participante" 
                                    className="border border-gray-300 text-xs 2xl:text-sm p-1" 
                                    value={filters.email}
                                    onChange={handleFilterChange}
                                />
                            </div>
                        </td>
                        <td className="p-2 text-left 2xl:p-4 text-sm">
                            <select 
                                name="etapa_cadastro"
                                className="border border-gray-300 p-1 text-xs 2xl:text-sm w-full"
                                onChange={handleFilterChange}
                                value={filters.etapa_cadastro}
                            >
                                <option value="">---</option>
                                <option value="etapa2">Etapa Inicial</option>
                                <option value="dados_adicionais">Dados Adicionais</option>
                                <option value="concluido">Documentos enviados</option>
                            </select>
                        </td>
                        <td className="p-2 text-left 2xl:p-4 text-sm">
                            <select 
                                name="conferido"
                                className="border border-gray-300 p-1 text-xs 2xl:text-sm w-full"
                                value={filters.conferido ? filters.conferido : 'Conferido'}
                                onChange={handleFilterChange}
                            >
                                <option value="">---</option>
                                <option value="true">Sim</option>
                                <option value="false">Não</option>
                            </select>
                        </td>
                        <td className="p-2 text-left 2xl:p-4 text-sm">
                            <select 
                                name="aprovado_bloqueado"
                                className="border border-gray-300 p-1 text-xs 2xl:text-sm w-full"
                                value={filters.aprovado_bloqueado ? filters.aprovado_bloqueado : 'Aprovado'}
                                onChange={handleFilterChange}
                            >
                                <option value="">---</option>
                                <option value="true">Sim</option>
                                <option value="false">Não</option>
                            </select>
                        </td>
                        <td className="p-2 text-left 2xl:p-4 text-sm">
                            <select 
                                name="confirmado"
                                className="border border-gray-300 p-1 text-xs 2xl:text-sm w-full"
                                value={filters.confirmado ? filters.confirmado : 'Confirmado'}
                                onChange={handleFilterChange}
                            >
                                <option value="">---</option>
                                <option value="true">Sim</option>
                                <option value="false">Não</option>
                            </select>
                        </td>
                        {/* <td className="p-2 text-left 2xl:p-4 pr-0 xl:pr-0 text-sm">
                            <div className="flex items-center">
                                <DatePicker
                                    selected={startDate}
                                    onChange={handleDateChange}
                                    selectsRange
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="dd/MM/yyyy"
                                    onCalendarOpen={() => setOpenDateRange(true)}
                                    onCalendarClose={() => setOpenDateRange(false)}
                                    placeholderText="Intervalo de datas"
                                    className="border border-gray-300 p-1 text-xs 2xl:text-sm w-full"
                                    isClearable
                                    locale="pt-BR"
                                    open={openDateRange}
                                />
                            </div>
                        </td> */}
                        <td className="-ml-2 py-1 text-left xl:py-4 text-sm"></td>
                        <td className="py-1 text-left xl:py-4 text-sm"></td>
                    </tr>
                </thead>
                <tbody  {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                            <MemberItem
                                key={row.id}
                                row={row}
                                onSelect={handleSelectMember}
                                isSelected={selectedMembers.includes(row.original.id)}
                            />
                        );
                    })}
                </tbody>
            </table>

            <div className="flex items-center mt-6 mb-4">
                <nav className="pagination isolate inline-flex space-x-1" aria-label="Pagination">
                    <button 
                        onClick={() => previousPage()} 
                        disabled={!canPreviousPage} 
                        className={`relative inline-flex items-center justify-center rounded w-9 h-9 ring-1 ring-inset ring-gray-300 ${canPreviousPage ? 'hover:text-white hover:bg-secondary hover:ring-secondary text-secondary' : 'text-slate-400'}`}
                    >
                        <span className="sr-only">Anterior</span>
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                            <path fillRule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clipRule="evenodd"></path>
                        </svg>
                    </button>

                    {pageIndex !== 0 && (
                        <button 
                            onClick={() => gotoPage(0)} 
                            disabled={!canPreviousPage} 
                            className={`relative inline-flex items-center justify-center font-medium rounded w-9 h-9 ring-1 ring-inset ring-gray-300 ${canPreviousPage ? 'hover:text-white hover:bg-secondary hover:ring-secondary text-secondary' : 'text-slate-400'}`}
                        >
                            1
                        </button>
                    )}

                    {pageIndex > 1 && pageOptions.length > 4 && (
                        <span className="relative inline-flex items-center justify-center font-medium rounded w-9 h-9 text-secondary ring-1 ring-inset ring-gray-300">...</span>
                    )}

                    {pageOptions[pageIndex - 1] ? (
                        <button 
                            onClick={() => gotoPage(pageIndex - 1)} 
                            className="relative inline-flex items-center justify-center font-medium rounded w-9 h-9 text-secondary ring-1 ring-inset ring-gray-300"
                        >
                            {pageIndex}
                        </button>
                    ) : null}


                    <span className="relative inline-flex items-center justify-center font-medium rounded w-9 h-9 text-white bg-secondary ring-1 ring-inset ring-secondary hover:bg-slate-700">
                        {pageIndex + 1}
                    </span>

                    {pageOptions[pageIndex + 1] && (
                        <button 
                            onClick={() => gotoPage(pageIndex + 1)} 
                            className="relative inline-flex items-center justify-center font-medium rounded w-9 h-9 text-secondary ring-1 ring-inset ring-gray-300"
                        >
                            {pageIndex + 2}
                        </button>
                    )}

                    {pageIndex < pageOptions.length - 3 && (
                        <span className="relative inline-flex items-center justify-center font-medium rounded w-9 h-9 text-gray-400 ring-1 ring-inset ring-gray-300">...</span>
                    )}

                    {pageIndex < pageOptions.length - 2 && (
                        <button 
                            onClick={() => gotoPage(pageOptions.length - 1)} 
                            className="relative inline-flex items-center justify-center font-medium rounded w-9 h-9 text-secondary ring-1 ring-inset ring-gray-300"
                        >
                            {pageOptions.length}
                        </button>
                    )}

                    <button 
                        onClick={() => nextPage()} 
                        disabled={!canNextPage} 
                        className={`relative inline-flex items-center justify-center rounded w-9 h-9 ring-1 ring-inset ring-gray-300 ${canNextPage ? 'hover:text-white hover:bg-secondary hover:ring-secondary text-secondary' : 'text-slate-400'}`}
                    >
                        <span className="sr-only">Próximo</span>
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                            <path fillRule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd"></path>
                        </svg>
                    </button>
                </nav>

                <div className="flex ml-auto gap-3">
                    <button onClick={handleDeleteSelected} className="bg-red-500 text-white text-sm 2xl:text-base px-4 h-9 rounded ml-auto transition-all enabled:hover:bg-red-600 disabled:opacity-50 disabled:cursor-not-allowed" disabled={selectedMembers.length <= 0 ? true : false}>
                        <FontAwesomeIcon icon={faTrash} className=" mr-2" />
                        Excluir Selecionados
                    </button>

                    <button onClick={handleApproveSelected} className="bg-green-500 text-white text-sm 2xl:text-base px-4 h-9 rounded ml-auto transition-all enabled:hover:bg-green-600 disabled:opacity-50 disabled:cursor-not-allowed" disabled={selectedMembers.length <= 0 ? true : false}>
                        <FontAwesomeIcon icon={faCheck} className=" mr-2" />
                        Aprovar Selecionados
                    </button>

                    <button onClick={handleCheckSelected} className="bg-green-500 text-white text-sm 2xl:text-base px-4 h-9 rounded ml-auto transition-all enabled:hover:bg-green-600 disabled:opacity-50 disabled:cursor-not-allowed" disabled={selectedMembers.length <= 0 ? true : false}>
                        <FontAwesomeIcon icon={faEye} className=" mr-2" />
                        Conferir Selecionados
                    </button>

                    <button onClick={handleConfirmSelected} className="bg-green-500 text-white text-sm 2xl:text-base px-4 h-9 rounded ml-auto transition-all enabled:hover:bg-green-600 disabled:opacity-50 disabled:cursor-not-allowed" disabled={selectedMembers.length <= 0 ? true : false}>
                        <FontAwesomeIcon icon={faPlane} className=" mr-2" />
                        Confirmar Selecionados
                    </button>
                </div>
            </div>

            {notification && notification.show && (
                <NotificationMessage
                    type={notification.type}
                    message={notification.message}
                    show={notification.show}
                    onClose={handleCloseNotification}
                />
            )}
        </>
    );
}

export default MemberList;

import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom';
import { useToken } from '../TokenContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { faIdBadge, faUser } from '@fortawesome/free-regular-svg-icons';

import MenuItem from './MenuItem';


function MenuAside() {
    const location = useLocation().pathname;
    const { tokenData } = useToken();

    const [openMenuIndex, setopenMenuIndex] = useState(null);

    const toggleMenu = (index) => {
        setopenMenuIndex(openMenuIndex === index ? null : index);
    };

    const menus = [
        { label: 'Participantes', icon: faIdBadge, href: '/manager/participantes', locations: ['/manager/participantes']},
        {
            label: 'Relatórios',
            icon: faChartLine,
            locations: [
                /^\/manager\/relatorios\/por-destino\/?$/,
                /^\/manager\/relatorios\/upload-de-usuarios\/.*/,
                /^\/manager\/relatorios\/geral\/?$/,
                /^\/manager\/relatorios\/seguro-de-viagem\/?$/
            ],
            subMenu: [
                { label: 'Por Destino', href: '/manager/relatorios/por-destino' },
                // { label: 'Upload de Usuários', href: '/manager/relatorios/upload-de-usuarios' },
                { label: 'Geral', href: '/manager/relatorios/geral' },
                { label: 'Seguro de Viagem', href: '/manager/relatorios/seguro-de-viagem' }
            ]
        },
        {
            label: 'Usuários',
            icon: faUser,
            href: '/manager/usuarios',
            locations: [
                /^\/manager\/usuarios\/?$/,
                /^\/manager\/usuarios\/adicionar\/?$/,
                /^\/manager\/usuarios\/editar\/.*/
            ]
        }
    ,
    ];

    return (
        <aside className="absolute left-0 top-0 z-9999 flex h-screen w-50 lg:w-72 flex-col overflow-y-hidden bg-primary duration-300 ease-linear lg:static lg:translate-x-0 -translate-x-full">
            <div className="flex items-center justify-between gap-2 px-6 py-5 lg:py-6">
                <Link to="/manager">
                    <img src={`/assets/img/admin/todeschini-logo.png`} className="w-full" alt="Logo" />
                </Link>

                <button className="block lg:hidden">
                    <svg className="fill-current" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z" fill=""></path>
                    </svg>
                </button>
            </div>


            <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
                <nav className="mt-5 px-4 py-4 lg:mt-8 lg:px-6">
                    <div>
                        <h3 className="mb-4 ml-4 text-sm font-medium text-white">MENU</h3>

                        <ul className="mb-6 flex flex-col gap-1.5">
                            {menus.map((menu, index) => (
                                <MenuItem
                                    key={index}
                                    index={index}
                                    label={menu.label}
                                    icon={menu.icon}
                                    subMenu={menu.subMenu}
                                    isOpen={openMenuIndex === index}
                                    onToggle={toggleMenu}
                                    location={location}
                                    locations={menu.locations}
                                    to={menu.href}
                                />
                            ))}
                        </ul>
                    </div>
                </nav>
            </div>
        </aside>
    );
}

export default MenuAside;
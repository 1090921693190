import { Link } from 'react-router-dom';

import MenuAside from '../Components//Manager/MenuAside';
import HeaderUser from '../Components/Manager/HeaderUser';
import HeaderNotifications from '../Components/Manager/HeaderNotifications';

function AdminLayout({ children }) {
    return (
        <>
            <div className="flex h-screen overflow-hidden">
                <MenuAside />
                <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
                    <header className="sticky top-0 z-50 flex w-full bg-white drop-shadow-md">
                        <div className="flex flex-grow items-center justify-between px-4 py-3 2xl:py-4 shadow-2 md:px-6 2xl:px-11">
                            <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
                                <button className="z-50 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm lg:hidden">
                                    <span className="relative block h-5.5 w-5.5 cursor-pointer">
                                        <span className="du-block absolute right-0 h-full w-full">
                                            <span className="relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-[0] duration-200 ease-in-out !w-full delay-300"></span>
                                            <span className="relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-150 duration-200 ease-in-out !w-full delay-400"></span>
                                            <span className="relative left-0 top-0 my-1 block h-0.5 w-0 rounded-sm bg-black delay-200 duration-200 ease-in-out !w-full delay-500"></span>
                                        </span>
                                        <span className="du-block absolute right-0 h-full w-full rotate-45">
                                            <span className="absolute left-2.5 top-0 block h-full w-0.5 rounded-sm bg-black delay-300 duration-200 ease-in-out !h-0 delay-[0]"></span>
                                            <span className="delay-400 absolute left-0 top-2.5 block h-0.5 w-full rounded-sm bg-black duration-200 ease-in-out !h-0 dealy-200"></span>
                                        </span>
                                    </span>
                                </button>

                                <Link to="/" className="block flex-shrink-0 lg:hidden" href="index.html">
                                    <img src={`src/images/logo/logo-icon.svg`} alt="Logo" />
                                </Link>
                            </div>

                            <div className="flex items-center ml-auto gap-3 2xsm:gap-7">
                                {/*<HeaderNotifications />*/}
                                <HeaderUser />
                            </div>
                        </div>
                    </header>
                    <div className="App">
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminLayout;
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './Components/ProtectedRoute';
import { TokenProvider } from './Components/TokenContext';

import Login from './pages/Login';
import Password from './pages/Password';
import Home from './pages/Home';
import HomeRedirect from './pages/HomeRedirect';
import Docs from './pages/Docs';
import Contact from './pages/Contact';

import AdminLogin from './pages/Manager/Login';
import AdminHome from './pages/Manager/Home';
import AdminMembers from './pages/Manager/Members';
import AdminEditMember from './pages/Manager/EditMember';
import AdminAddMember from './pages/Manager/AddMember';
import AdminReportDestiny from './pages/Manager/ReportDestiny';
import AdminReportGeneral from './pages/Manager/ReportGeneral';
import AdminReportInsurance from './pages/Manager/ReportInsurance';
import AdminUsers from './pages/Manager/Users';
import AdminAddUser from './pages/Manager/AddUser';
import AdminEditUser from './pages/Manager/EditUser';

const App = () => {
    return (
        <TokenProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/alterar-senha/:token" element={<Password />} />
                    <Route path="/painel/dados-cadastrais" element={<ProtectedRoute element={<Home />} />} />
                    <Route path="/painel/upload-documentos" element={<ProtectedRoute element={<Docs />} />} />
                    <Route path="/painel/contato" element={<ProtectedRoute element={<Contact />} />} />
                    <Route path="/" element={<ProtectedRoute element={<HomeRedirect />} />} />

                    <Route path="/manager/login" element={<AdminLogin />} />
                    <Route path="/manager/" element={<ProtectedRoute admin={true} element={<AdminHome />} />} />

                    <Route path="/manager/participantes" element={<ProtectedRoute admin={true} element={<AdminMembers />} />} />
                    <Route path="/manager/participante/editar/:id" element={<ProtectedRoute admin={true} element={<AdminEditMember />} />} />
                    <Route path="/manager/participante/adicionar" element={<ProtectedRoute admin={true} element={<AdminAddMember />} />} />

                    <Route path="/manager/relatorios/por-destino" element={<ProtectedRoute admin={true} element={<AdminReportDestiny />} />} />
                    <Route path="/manager/relatorios/geral" element={<ProtectedRoute admin={true} element={<AdminReportGeneral />} />} />
                    <Route path="/manager/relatorios/seguro-de-viagem" element={<ProtectedRoute admin={true} element={<AdminReportInsurance />} />} />

                    <Route path="/manager/usuarios" element={<ProtectedRoute admin={true} element={<AdminUsers />} />} />
                    <Route path="/manager/usuarios/adicionar" element={<ProtectedRoute element={<AdminAddUser />} />} />
                    <Route path="/manager/usuarios/editar/:id" element={<ProtectedRoute element={<AdminEditUser />} />} />
                </Routes>
            </Router>
        </TokenProvider>
    );
};

export default App;